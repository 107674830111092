var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "form",
    {
      attrs: { method: "post" },
      on: {
        submit: function ($event) {
          $event.preventDefault()
          return _vm.validateFields.apply(null, arguments)
        },
      },
    },
    [
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-sm-6" },
          [
            _c(
              "Select",
              {
                directives: [
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: "required",
                    expression: "'required'",
                  },
                ],
                attrs: {
                  label: "Salutation",
                  error: _vm.errors.first("Salutation"),
                },
                model: {
                  value: _vm.formFields.salutation,
                  callback: function ($$v) {
                    _vm.$set(_vm.formFields, "salutation", $$v)
                  },
                  expression: "formFields.salutation",
                },
              },
              [
                _c("option", { attrs: { value: "" } }),
                _vm._v(" "),
                _c("option", { attrs: { value: "Tan Sri" } }, [
                  _vm._v("Tan Sri"),
                ]),
                _vm._v(" "),
                _c("option", { attrs: { value: "Puan Sri" } }, [
                  _vm._v("Puan Sri"),
                ]),
                _vm._v(" "),
                _c("option", { attrs: { value: "Dato Seri" } }, [
                  _vm._v("Dato Seri"),
                ]),
                _vm._v(" "),
                _c("option", { attrs: { value: "Datin Seri" } }, [
                  _vm._v("Datin Seri"),
                ]),
                _vm._v(" "),
                _c("option", { attrs: { value: "Dato" } }, [_vm._v("Dato")]),
                _vm._v(" "),
                _c("option", { attrs: { value: "Datin" } }, [_vm._v("Datin")]),
                _vm._v(" "),
                _c("option", { attrs: { value: "Mr" } }, [_vm._v("Mr")]),
                _vm._v(" "),
                _c("option", { attrs: { value: "Mrs" } }, [_vm._v("Mrs")]),
                _vm._v(" "),
                _c("option", { attrs: { value: "Ms" } }, [_vm._v("Ms")]),
                _vm._v(" "),
                _c("option", { attrs: { value: "Dr" } }, [_vm._v("Dr")]),
                _vm._v(" "),
                _c("option", { attrs: { value: "Prof" } }, [_vm._v("Prof")]),
              ]
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "col-sm-6" },
          [
            _c("Input", {
              directives: [
                {
                  name: "validate",
                  rawName: "v-validate",
                  value: "required",
                  expression: "'required'",
                },
              ],
              attrs: {
                label: "Name",
                type: "text",
                error: _vm.errors.first("Name"),
              },
              model: {
                value: _vm.formFields.name,
                callback: function ($$v) {
                  _vm.$set(_vm.formFields, "name", $$v)
                },
                expression: "formFields.name",
              },
            }),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-sm-6" },
          [
            _c("Input", {
              directives: [
                {
                  name: "validate",
                  rawName: "v-validate",
                  value: "required|email",
                  expression: "'required|email'",
                },
              ],
              attrs: {
                label: "Email",
                type: "email",
                error: _vm.errors.first("Email"),
              },
              model: {
                value: _vm.formFields.email,
                callback: function ($$v) {
                  _vm.$set(_vm.formFields, "email", $$v)
                },
                expression: "formFields.email",
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "col-sm-6" },
          [
            _c("Input", {
              directives: [
                {
                  name: "validate",
                  rawName: "v-validate",
                  value: "required|numeric",
                  expression: "'required|numeric'",
                },
              ],
              attrs: {
                label: "Phone",
                type: "text",
                error: _vm.errors.first("Phone"),
              },
              model: {
                value: _vm.formFields.phone,
                callback: function ($$v) {
                  _vm.$set(_vm.formFields, "phone", $$v)
                },
                expression: "formFields.phone",
              },
            }),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-12" },
          [
            _c("TextArea", {
              directives: [
                {
                  name: "validate",
                  rawName: "v-validate",
                  value: "required",
                  expression: "'required'",
                },
              ],
              attrs: {
                label: "Message",
                type: "textarea",
                error: _vm.errors.first("Message"),
              },
              model: {
                value: _vm.formFields.message,
                callback: function ($$v) {
                  _vm.$set(_vm.formFields, "message", $$v)
                },
                expression: "formFields.message",
              },
            }),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-12" },
          [_c("styled-button", { attrs: { text: _vm.buttonMsg } })],
          1
        ),
      ]),
      _vm._v(" "),
      _c(
        "transition",
        { attrs: { css: false }, on: { enter: _vm.enter, leave: _vm.leave } },
        [
          _vm.modalOpen
            ? _c("div", { staticClass: "modal--form" }, [
                _c(
                  "div",
                  { staticClass: "modal--inner" },
                  [
                    _c("h1", [_vm._v("THANK YOU FOR CONTACTING US.")]),
                    _vm._v(" "),
                    _c("p", [_vm._v("We will get back to you soon.")]),
                    _vm._v(" "),
                    _c(
                      "StyledButton",
                      { on: { "button-click": _vm.modalClose } },
                      [_vm._v("CLOSE")]
                    ),
                  ],
                  1
                ),
              ])
            : _vm._e(),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }