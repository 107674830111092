<template lang="html">
  <transition
    v-bind:css="false"
    v-on:before-leave="beforeLeave"
    v-on:enter="enter"
    v-on:leave="leave"
    v-on:after-leave="afterLeave"
  >
    <div class="tab" v-show='isVisible' v-bind:class="{'is-visible': isVisible}">
      <slot></slot>
    </div>
  </transition>
</template>

<script>
import anime      from 'animejs';
import _kebabCase from 'lodash/kebabCase';

export default {
  name: 'Tab',

  props: {
    name: {
      type: String,
      required: true
    },

    currentTab: {
      type: String
    }
  },

  computed: {
    isVisible: function(){
      return this.currentTab == _kebabCase(this.name) ? true : false;
    }
  },

  methods: {
    beforeLeave(tab){
      tab.style.position = 'absolute';
    },

    afterLeave(tab){
      tab.style.position = '';
    },

    enter(tab, done){
      anime.remove(tab);

      anime({
        targets: tab,
        opacity: [0, 1],
        translateY: [15, 0],

        easing: 'easeOutQuart',
        duration: 550,

        complete: () =>{
          tab.removeAttribute('style');
          done();
        }
      });
    },

    leave(tab, done){
      anime.remove(tab);

      anime({
        targets: tab,
        opacity: [1, 0],
        translateY: [0, 15],

        easing: 'easeOutQuart',
        duration: 550,

        complete: done
      });
    }
  }
};
</script>

<style lang="css">
</style>
