import _debounce from 'lodash/debounce';

/**
 * @constructor
 * GoogleTracker
 * -------------------------------------------
 * Custom class for the purpose of tracking events, either via adding a custom HTML attribute which listens for clicks,
 * or calling a function to send an event
 *
 *
 * How to Use
 * -------------------------------------------
 * HTML attribute        gtag-click="{eventCategory} | {eventAction} | {eventLabel} | {eventValue}"
 *                       params are seperated with "|"
 *
 * Function              sendEvent({eventCategory}, {eventAction}, {eventLabel}, {eventValue});
 *
 * Naming Convention
 * -------------------------------------------
 * Names should be in Title Case for consistency. Although any other casing format can be used as long as it's consistent.
 *
 * Parameters Overview
 * -------------------------------------------
 *
 *
 * @param {string} eventCategory                  {Category} : {detail 1} : {detail 2} ....
 *                                                The group of similar events to track.
 *                                                eg: Outbound Links, YouTube Videos, Happenings, Events
 *
 * @param {string} eventAction                    {Object} : {detail 1} : {detail 2} ....
 *                                                Although called 'action', this is used for the name of the object to track.
 *                                                Objects referring to an event or a page element or page feature.
 *                                                eg: Website Launch, CNY Event, Navigation Menu, Page Scroll
 *
 * @param {string} eventLabel                     {Element} : {Label} : {detail 1} : {detail 2} ....
 *                                                Name of the page element, whose interaction is tracked.
 *                                                eg: button label, item name, video titles
 *
 * @param {number} [eventValue] (optional)        Numerical value assigned to the event
 *                                                eg: scroll amount, current video time
 *
 * Examples
 * -------------------------------------------
 * Assume a CTA button promoting a new website, www.awesomesite.com
 *
 * eventCategory      Events
 * eventAction        New Website : www.awesomesite.com
 * eventLabel         Button : Discover More
 *
 * <button gtag-click="Events | New Website : www.awesomesite.com | Button : Discover More">Discover More</button>
 *
 * -------------------------------------------
 * Assume need for tracking navigation menu clicks
 *
 * eventCategory      Navigation Menu
 * eventAction        Main Links
 * eventLabel         About Us
 *
 * <a gtag-click="Navigation Menu | Main Links | About Us">About Us</a>
 */

class GoogleTracker{
  initClickEvents(){
    // remove old trackers
    this.resetTrackers();

    // get list of tracked buttons
    this.clickTrackers = Array.from(document.querySelectorAll('[gtag-click]'));
    // generate tracker and their send function
    this.clickTrackers.forEach((tracker, index) => this.clickTrackers[index] = this.generateTracker(tracker));

    // bind click events
    this.clickTrackers.forEach(tracker => {
      tracker.el.addEventListener('click', tracker.send);
      tracker.el.addEventListener('auxclick', tracker.send);
    });
  }

  resetTrackers(){
    // if no trackers return
    if(this.clickTrackers == undefined) return;

    // remmove all trackers
    this.clickTrackers.forEach(tracker => {
      tracker.el.removeEventListener('click', tracker.send);
      tracker.el.removeEventListener('auxclick', tracker.send);
    });
  }

  generateTracker(tracker){
    // get parameters from string
    const params = tracker.getAttribute('gtag-click').split('|');

    // assign params to each corresponding label
    const eventCategory = params[0] ? params[0].trim() : null;
    const eventAction   = params[1] ? params[1].trim() : null;
    const eventLabel    = params[2] ? params[2].trim() : null;
    const eventValue    = params[3] ? params[3].trim() : null;

    // create array of parameters to be passed
    const gtagParams = {
      'event_category': eventCategory,
      'event_label': eventLabel,
      'value': eventValue
    };

    // create debounced event to prevent spam
    /* eslint-disable */
    const send = _debounce(() => {
                    gtag('event', eventAction, gtagParams);
                  }, 1000, {
                    leading: true,
                    trailing: false
                  });
    /* eslint-enable */

    // return object of tracker and its send function
    return {el: tracker, send: send};
  }

  /** @function */
  sendEvent(eventCategory, eventAction, eventLabel, eventValue){
    /* eslint-disable */
    gtag('event', eventAction, {
      'event_category': eventCategory,
      'event_label': eventLabel,
      'value': eventValue
    });
    /* eslint-enable */
  }
}

const googleTracker = new GoogleTracker();
export default googleTracker;
