<template lang="html">
  <div class="sub--navigation">
    <ul>
      <li
        v-for='item in parsedItems'
        v-bind:data-value='item.value'
        v-html='item.name'
        v-on:click='clickEvent($event, item)'
        v-bind:class='{"is-current": item.value == current}'
      ></li>
    </ul>
  </div>
</template>

<script>
import _kebabCase from 'lodash/kebabCase';

import SVG        from 'svg.js';
import anime      from 'animejs';

export default {
  name: 'SubNavigationList',
  data(){
    return {
      current: ''
    };
  },
  props: {
    items: {
      type: Array,
      required: true
    }
  },

  mounted(){
    if(document.readyState != 'complete') window.addEventListener('load', () => this.buildBackground());
  },

  updated(){
    if(!this.builtBackground && document.readyState == 'complete'){
      this.buildBackground();
    }
  },

  computed: {
    // parse item to allow string / objects
    parsedItems: function(){
      // array to return
      const items = [];

      if(this.items.length == 0) return items;

      // iterate over each entry
      this.items.forEach(el => {
        // if its a string, turn it to an object
        if(typeof el == 'string') items.push({name: el, value: _kebabCase(el)});
        // if its an object, insert it
        else if(typeof el == 'object') items.push({name: el.name, value: _kebabCase(el.name)});
      });

      // set first in list to current
      this.current = items[0].value;

      // return final array
      return items;
    }
  },

  methods: {
    buildBackground(){
      // get main list element
      const list = this.$el.querySelector('ul');
      // get first in list as reference
      const reference = list.querySelector('li');

      // get width + margin
      const w    = reference.clientWidth;
      // get height
      const h    = reference.clientHeight;

      // create SVG with 100% size
      this.draw = SVG(list).size('100%', '100%');
      this.polygon = this.draw.group();

      // create outer stroke
      this.polyStroke = this.polygon.polygon(`${w-15}, 0 0, 0 0, ${h} ${w} ${h}`);
      this.polyStroke.stroke({width: 1, color: '#000'}).fill('none');

      // create inner fill
      this.polyFill = this.polygon.polygon(`${w-15}, 0 0, 0 0, ${h} ${w} ${h}`);
      this.polyFill.fill('#000');

      this.polygon.x(5);

      // create anim timeline
      const anim = anime.timeline({
        easing: 'easeInOutCubic',
        duration: 600,
      });

      // animate
      anim
      .add({
        targets: this.$el,
        opacity: [0, 1],
        offset: 0
      })
      .add({
        targets: this.polyStroke.node,
        strokeDashoffset: [anime.setDashoffset, 0],
        offset: 0
      })
      .add({
        targets: this.polyFill.node,
        translateX: ['-200px', '0'],
        offset: 0
      });

      this.builtBackground = true;
    },

    clickEvent(event, item){
      // set value to current
      this.current = item.value;

      this.$emit('change', item.value);

      // move svg background
      this.moveBackground(event);
    },

    moveBackground(event){
      // get new values
      const newPosX    = event.target.offsetLeft + 5;
      const newPosY    = event.target.offsetTop;
      const newWidth  = event.target.clientWidth;
      const newHeight = event.target.clientHeight;

      // remove from animation queue
      anime.remove([this.polyStroke.node, this.polyFill.node]);

      // animate
      this.polygon.stop();
      this.polygon.animate(600, this.easeOutQuart).move(newPosX, newPosY);
      
      anime({
        targets: [this.polyStroke.node, this.polyFill.node],
        points: [`${newWidth-15}, 0 0, 0 0, ${newHeight} ${newWidth} ${newHeight}`],

        easing: 'easeOutQuart',
        duration: 600
      });
    },

    easeOutQuart(pos){
      return -(Math.pow((pos-1), 4) -1);
    }
  }
};
</script>
