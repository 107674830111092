var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "form",
    {
      attrs: { method: "get" },
      on: {
        submit: function ($event) {
          $event.preventDefault()
          return _vm.validateFields.apply(null, arguments)
        },
      },
    },
    [
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-sm-6" }, [
          _c("h2", [_vm._v("CONTACT INFORMATION")]),
          _vm._v(" "),
          _c("div", { staticClass: "row" }, [
            _c(
              "div",
              { staticClass: "col-sm-6" },
              [
                _c(
                  "Select",
                  {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "required",
                        expression: "'required'",
                      },
                    ],
                    attrs: {
                      label: "Salutation",
                      error: _vm.errors.first("Salutation"),
                    },
                    model: {
                      value: _vm.formFields.salutation,
                      callback: function ($$v) {
                        _vm.$set(_vm.formFields, "salutation", $$v)
                      },
                      expression: "formFields.salutation",
                    },
                  },
                  [
                    _c("option", { attrs: { value: "" } }),
                    _vm._v(" "),
                    _c("option", { attrs: { value: "Tan Sri" } }, [
                      _vm._v("Tan Sri"),
                    ]),
                    _vm._v(" "),
                    _c("option", { attrs: { value: "Puan Sri" } }, [
                      _vm._v("Puan Sri"),
                    ]),
                    _vm._v(" "),
                    _c("option", { attrs: { value: "Dato Seri" } }, [
                      _vm._v("Dato Seri"),
                    ]),
                    _vm._v(" "),
                    _c("option", { attrs: { value: "Datin Seri" } }, [
                      _vm._v("Datin Seri"),
                    ]),
                    _vm._v(" "),
                    _c("option", { attrs: { value: "Dato" } }, [
                      _vm._v("Dato"),
                    ]),
                    _vm._v(" "),
                    _c("option", { attrs: { value: "Datin" } }, [
                      _vm._v("Datin"),
                    ]),
                    _vm._v(" "),
                    _c("option", { attrs: { value: "Mr" } }, [_vm._v("Mr")]),
                    _vm._v(" "),
                    _c("option", { attrs: { value: "Mrs" } }, [_vm._v("Mrs")]),
                    _vm._v(" "),
                    _c("option", { attrs: { value: "Ms" } }, [_vm._v("Ms")]),
                    _vm._v(" "),
                    _c("option", { attrs: { value: "Dr" } }, [_vm._v("Dr")]),
                    _vm._v(" "),
                    _c("option", { attrs: { value: "Prof" } }, [
                      _vm._v("Prof"),
                    ]),
                  ]
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "col-sm-6" },
              [
                _c("Input", {
                  directives: [
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: "required",
                      expression: "'required'",
                    },
                  ],
                  attrs: {
                    label: "Name",
                    type: "text",
                    error: _vm.errors.first("Name"),
                  },
                  model: {
                    value: _vm.formFields.name,
                    callback: function ($$v) {
                      _vm.$set(_vm.formFields, "name", $$v)
                    },
                    expression: "formFields.name",
                  },
                }),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "row" }, [
            _c(
              "div",
              { staticClass: "col-sm-6" },
              [
                _c("Input", {
                  directives: [
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: "required|email",
                      expression: "'required|email'",
                    },
                  ],
                  attrs: {
                    label: "Email",
                    type: "email",
                    error: _vm.errors.first("Email"),
                  },
                  model: {
                    value: _vm.formFields.email,
                    callback: function ($$v) {
                      _vm.$set(_vm.formFields, "email", $$v)
                    },
                    expression: "formFields.email",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "col-sm-6" },
              [
                _c(
                  "Select",
                  {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "required",
                        expression: "'required'",
                      },
                    ],
                    attrs: { label: "Age", error: _vm.errors.first("Age") },
                    model: {
                      value: _vm.formFields.age,
                      callback: function ($$v) {
                        _vm.$set(_vm.formFields, "age", $$v)
                      },
                      expression: "formFields.age",
                    },
                  },
                  [
                    _c("option", { attrs: { value: "" } }),
                    _vm._v(" "),
                    _c("option", { attrs: { value: "< 18" } }, [
                      _vm._v(" Under 18"),
                    ]),
                    _vm._v(" "),
                    _c("option", { attrs: { value: "18 - 21" } }, [
                      _vm._v(" 18 - 21"),
                    ]),
                    _vm._v(" "),
                    _c("option", { attrs: { value: "21 - 30" } }, [
                      _vm._v(" 21 - 30"),
                    ]),
                    _vm._v(" "),
                    _c("option", { attrs: { value: "31 - 40" } }, [
                      _vm._v(" 31 - 40"),
                    ]),
                    _vm._v(" "),
                    _c("option", { attrs: { value: "41 - 50" } }, [
                      _vm._v(" 41 - 50"),
                    ]),
                    _vm._v(" "),
                    _c("option", { attrs: { value: "51 - 60" } }, [
                      _vm._v(" 51 - 60"),
                    ]),
                    _vm._v(" "),
                    _c("option", { attrs: { value: "61 - 70" } }, [
                      _vm._v(" 61 - 70"),
                    ]),
                    _vm._v(" "),
                    _c("option", { attrs: { value: "> 70" } }, [
                      _vm._v(" Above 70"),
                    ]),
                  ]
                ),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "row" }, [
            _c(
              "div",
              { staticClass: "col-12" },
              [
                _c("Input", {
                  directives: [
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: "required|numeric",
                      expression: "'required|numeric'",
                    },
                  ],
                  attrs: {
                    label: "Contact Number",
                    type: "text",
                    error: _vm.errors.first("Contact Number"),
                  },
                  model: {
                    value: _vm.formFields.number,
                    callback: function ($$v) {
                      _vm.$set(_vm.formFields, "number", $$v)
                    },
                    expression: "formFields.number",
                  },
                }),
              ],
              1
            ),
          ]),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "col-sm-5 offset-sm-1" },
          [
            _vm._m(0),
            _vm._v(" "),
            _c("Checkbox", {
              directives: [
                {
                  name: "validate",
                  rawName: "v-validate",
                  value: "required",
                  expression: "'required'",
                },
              ],
              attrs: {
                set: "Interested Property",
                checkboxes: [
                  "Binastra Business Park @ Pulau Meranti",
                  "Binastra Business Park @ Pulau Meranti 2",
                  "Binastra Business Park @ Alam Damai",
                  "Binastra Cochrane Park 1",
                  "Binastra Cochrane Park 2",
                ],
                error: _vm.errors.has("Interested Property"),
              },
              model: {
                value: _vm.formFields.interestedProperties,
                callback: function ($$v) {
                  _vm.$set(_vm.formFields, "interestedProperties", $$v)
                },
                expression: "formFields.interestedProperties",
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "col-sm-8" },
          [
            _c("p", [_vm._v("(Note: all fields are mandatory)")]),
            _vm._v(" "),
            _c(
              "Checkbox",
              {
                directives: [
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: "required",
                    expression: "'required'",
                  },
                ],
                attrs: {
                  label: "Terms and Conditions",
                  error: _vm.errors.first("Terms and Conditions"),
                },
                model: {
                  value: _vm.formFields.tac,
                  callback: function ($$v) {
                    _vm.$set(_vm.formFields, "tac", $$v)
                  },
                  expression: "formFields.tac",
                },
              },
              [
                _vm._v(
                  "\n        By submitting this form, you hereby agree that Binastra Land may collect, obtain, store and process your personal data that you provide in this form for the purpose of receiving updates, news, promotional and marketing mails or materials from Binastra Land.\n      "
                ),
              ]
            ),
            _vm._v(" "),
            _c("styled-button", { attrs: { text: _vm.buttonMsg } }),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c(
        "transition",
        { attrs: { css: false }, on: { enter: _vm.enter, leave: _vm.leave } },
        [
          _vm.modalOpen
            ? _c("div", { staticClass: "modal--form" }, [
                _c(
                  "div",
                  { staticClass: "modal--inner" },
                  [
                    _c("h1", [_vm._v("THANK YOU FOR REGISTERING WITH US.")]),
                    _vm._v(" "),
                    _c("p", [_vm._v("We will get back to you soon.")]),
                    _vm._v(" "),
                    _c(
                      "StyledButton",
                      { on: { "button-click": _vm.modalClose } },
                      [_vm._v("CLOSE")]
                    ),
                  ],
                  1
                ),
              ])
            : _vm._e(),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("h2", [_c("br")])
  },
]
render._withStripped = true

export { render, staticRenderFns }