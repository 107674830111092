<template lang="html">
  <div class="section">
    <slot name="outer:before"></slot>
    <div class="section--inner">
      <slot></slot>
    </div>
    <slot name="outer:after"></slot>
  </div>
</template>

<script>
export default {
  name: 'SectionContent'
};
</script>

<style lang="css">
</style>
