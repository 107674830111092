<template>
  <div class="slide">
    <div class="row no-gutters">
      <div class="col-sm-4">
        <div class="slide--header">
          <slot name="header"></slot>
        </div>
        <div class="slide--content">
          <slot name="content"></slot>

          <StyledButton
            v-for="(b, index) in button"
            v-bind:key="index"
            ref="button"
            v-bind:href="b.href"
            v-bind:target="b.target"
            v-bind:rel="b.rel"
            v-bind:auto="false"
            v-bind:gtag-click="b.tracker"
          >
            {{b.text}}
          </StyledButton>
        </div>
      </div>
    </div>
    <div class="slide--background"></div>
  </div>
</template>

<script>
import StyledButton from 'components/StyledButton';

import anime from 'animejs';

export default {
  name: 'Slide',
  components: {StyledButton},
  props: {
    button: { type: Array },
    tracker: { type: String }
  },

  data(){
    return {
      linesLength: '',
      lines: [],

      animated: false,

      headerStagger: 200,
      headerDuration: 550,

      contentStagger: 250,
      contentDuration: 600
    };
  },

  mounted(){
    // get contents
    this.headerInner  = this.$el.querySelectorAll('.slide--header > *');
    // this.contentInner = this.$el.querySelector('.slide--content p');

    // wrap prerequisite structure
    this.headerInner.forEach(line => line.innerHTML = `<span></span><span> ${line.innerHTML} </span>`);

    // add span tags to every word
    // this.contentInner.innerHTML = this.contentInner.innerHTML.replace(/(\S+\s*)/g, '<span><span>$1</span></span>');

    // set states
    document.addEventListener('DOMContentLoaded', () => {
      this.setStartState();
    });
  },

  methods: {
    setStartState(){
      if(this.animated) return;

      // reset and set to hidden
      this.headerInner.forEach(line => {
        line.querySelector('span').removeAttribute('style');
        line.querySelector('span:last-child').style.visibility = 'hidden';
      });

      Array.from(this.$el.querySelectorAll('.slide--content p')).forEach(child => {
        child.style.opacity = 0
      })

      // this.$el.querySelectorAll('.slide--content span > span').forEach(span => span.style.transform = 'translateY(100%)');
    },

    animate(){
      if(this.animated) return;

      this.animateHeader();
      this.animateContent();

      if(this.$refs.button) this.$refs.button.forEach(b => b.animate());
    },

    animateHeader(){
      this.headerInner.forEach((line, index) => {
        anime.timeline({
          targets: line.querySelector('*:first-child'),
          duration: this.headerDuration,
          easing: 'easeOutQuart',

          delay: ()=> this.headerStagger*index,
          complete: ()=> this.animated = true
        })
          .add({
            width: '105%',
            complete: () => line.querySelector('*:last-child').style.visibility = ''
          })
          .add({
            scaleX: 0,
            offset: this.headerDuration*1.3
          });
      });
    },

    animateContent(){
      anime({
        targets: this.$el.querySelectorAll('.slide--content p'),
        opacity: [0, 1],
        translateY: ['100%', 0],

        easing: 'easeOutQuart',
        duration: this.contentDuration,
        delay: (target, index) => this.contentStagger*index + this.headerDuration
      })
    }
    // animateContent(){
    //   // get inner spans
    //   const innerSpans = this.contentInner.querySelectorAll('span > span');

    //   // get line height
    //   const lineHeight = window.getComputedStyle(this.contentInner).lineHeight.split('px')[0];
    //   this.linesLength = Math.round(this.contentInner.offsetHeight/lineHeight);

    //   // init array of words per line
    //   this.lines = [this.linesLength];
    //   // init each row array
    //   for(let i = 0; i < this.linesLength; i++) this.lines[i] = [];

    //   let cx = 0;
    //   innerSpans.forEach((span, index) => {
    //     // skip height check for first
    //     if(index == 0){
    //       this.lines[cx].push(span);
    //       return;
    //     }

    //     // increment row if different height
    //     if(span.offsetTop != innerSpans[index - 1].offsetTop) cx++;

    //     // add to line array
    //     this.lines[cx].push(span);
    //   });

    //   this.lines.forEach((line, index) => {
    //     anime({
    //       targets: line,
    //       translateY: ['100%', 0],

    //       easing: 'easeOutQuart',
    //       duration: this.contentDuration,
    //       delay: this.contentStagger*index + this.headerDuration
    //     });
    //   });
    // }
  }
};
</script>
