<template lang="html">
  <footer>
    <p>© {{ currentYear }} Binastra Land. All Rights Reserved.</p>
    <ul>
      <li v-on:click="openDisclaimer">Disclaimer</li>
      /
      <li v-on:click="openPPolicy">Privacy Policy</li>
    </ul>

    <transition
      v-bind:css="false"
      v-on:enter="enter"
      v-on:leave="leave"
    >
      <div class="modal--legal" v-if="modalContent != ''">
        <div class="modal--inner">
          <div class="modal--close" v-on:click="closeModal"></div>
          <div class="modal--content" v-html="modalContent"></div>
        </div>
      </div>
    </transition>
  </footer>
</template>

<script>
import axios from 'axios';
import anime from 'animejs';

export default {
  name: 'FooterMenu',

  data(){
    return {
      privacyPolicy: '',
      disclaimer: '',
      modalContent: '',
      currentYear: ''
    };
  },

  mounted(){
    axios.get('/disclaimer?query').then(resp => this.disclaimer = resp.data);
    axios.get('/privacy-policy?query').then(resp => this.privacyPolicy = resp.data);

    this.currentYear = new Date().getFullYear()
  },

  methods: {
    openDisclaimer(){
      if(this.modalContent == '') this.modalContent = this.disclaimer;
      else this.modalContent = '';
    },

    openPPolicy(){
      if(this.modalContent == '') this.modalContent = this.privacyPolicy;
      else this.modalContent = '';
    },

    closeModal(){
      this.modalContent = '';
    },

    enter(el, done){
      document.body.style.marginRight = `${window.innerWidth - document.body.clientWidth}px`;
      document.body.style.overflow = 'hidden';

      anime({
        targets: el,
        opacity: [0, 1],
        duration: 450,
        easing: 'easeOutQuart'
      });

      anime({
        targets: el.querySelector('.modal--inner'),
        translateY: ['20vh', 0],
        duration: 450,
        easing: 'easeOutQuart',

        complete: done
      });
    },

    leave(el, done){
      el.style.overflow = 'hidden';
      document.body.style.marginRight = '';
      document.body.style.overflow    = '';

      anime({
        targets: el,
        opacity: [1, 0],
        duration: 450,
        easing: 'easeOutQuart'

      });
      anime({
        targets: el.querySelector('.modal--inner'),
        translateY: [0, '20vh'],
        duration: 450,
        easing: 'easeOutQuart',

        complete: done
      });
    }
  }
};
</script>

<style lang="css">
</style>
