<template lang="html">
  <header>
    <div class="header--logo">
      <a href="/?skip" gtag-click="Navigation Menu | Logo">
        <img v-bind:src="`${window._templateDir}/img/logo-binastraland-new.svg`" alt="">
      </a>
    </div>

    <div
      class="hamburger"
      v-on:click='toggleNavigation'
      v-bind:class='{"is-active": navActive}'
      gtag-click="Navigation Menu | Hamburger"
    >
      <span></span>
      <span></span>
      <span></span>
    </div>

    <HeaderNavigation
    v-bind:class='{"is-active": navActive}'
    v-bind:navActive='navActive'
    />
  </header>
</template>

<script>
import HeaderNavigation from 'components/HeaderMenu/Navigation';

export default {
  name: 'HeaderMenu',
  components: { HeaderNavigation },

  data(){
    return {
      window: window,
      navActive: false
    };
  },

  methods: {
    toggleNavigation(){
      this.navActive = !this.navActive;
    }
  }
};
</script>

<style lang="css">
</style>
