// ===============================================
// Imports
// ============================================
// Polyfills
// import 'core-js/es6/object';
// import 'core-js/es6/math';
// import 'core-js/es6/array';
// import 'core-js/es6/promise';

import 'babel-polyfill';
import './polyfills/nodeList.forEach';

// Vue
import Vue                from 'vue';
import VeeValidate        from 'vee-validate';

// Vue components
import HeaderMenu         from 'components/HeaderMenu';
import FooterMenu         from 'components/FooterMenu';

import OverlayVideo       from 'components/OverlayVideo';

import FloatingContent    from 'components/FloatingContent';
import SectionContent     from 'components/SectionContent';

import TabSection         from 'components/TabSection';
import Tab                from 'components/TabSection/Tab';

import HomeCarousel       from 'components/HomeCarousel';
import CollectionCarousel from 'components/CollectionCarousel';
import NewsCarousel       from 'components/NewsCarousel';
import CareerCarousel       from 'components/CareerCarousel';

import ContactForm        from 'components/ContactForm';
import RegistrationForm   from 'components/RegistrationForm';

import LazyVideo          from 'components/LazyVideo';

// Utilities
import Utils         from 'utils';
import logoFade      from './components/logoFade';
import googleTracker from './tracker';

// ===============================================
// Nitty Gritty Bits
// ============================================

// list of default components
const defaultComponents = {
  HeaderMenu,
  FooterMenu
};

// init object to hold page specific components
let pageComponents = {};

// if home page
Utils.pageRunner('home', ()=> {
  pageComponents = { OverlayVideo, HomeCarousel, FloatingContent };

});

// About Us page components
Utils.pageRunner('about-us', ()=> pageComponents = { SectionContent, TabSection, Tab, FloatingContent });

// Collection page components
Utils.pageRunner('collection', ()=> pageComponents = { CollectionCarousel });

// News and Events page components
Utils.pageRunner('news-events', ()=> pageComponents = { NewsCarousel, TabSection, Tab, LazyVideo });

// Career page components
Utils.pageRunner('career', ()=> pageComponents = { CareerCarousel, TabSection, Tab, LazyVideo });

// Contact page components
Utils.pageRunner('contact-us', ()=> pageComponents = { SectionContent, ContactForm });

// Registration page components
Utils.pageRunner('registration', ()=> pageComponents = { SectionContent, RegistrationForm });

// Disclaimer / Privacy Policy
Utils.pageRunner(['disclaimer', 'privacy-policy'], ()=> pageComponents = { SectionContent });


// ===============================================
// Vue Plugins
// ============================================

// Init VeeValidate on Registration/ Contact Us page
Utils.pageRunner(['contact-us', 'registration'], () => {
  Vue.use(VeeValidate, {
    classes: true,
    dictionary: {
      en: {
        messages: {
          required: () => 'This field is required.',
          alpha_spaces: () => 'Only alphabets are allowed.',
          email: () => 'Please enter a valid email.',
          numeric: () => 'Only numbers are allowed.'
        }
      }
    }
  });
});

// ===============================================
// Create Vue Instance
// ============================================
const vm = new Vue({
  el: '#wrapper',
  components: Object.assign(defaultComponents, pageComponents),

  mounted: function(){
    // ===============================================
    // Activate Google Tracker
    // ============================================
    googleTracker.initClickEvents();
  }
});

// Make carousel wait for video end
Utils.pageRunner('home', () => {
  vm.$on('video-ended', () => {
    vm.$refs.carousel.startPagination = true;
    // if(!Utils.checkMobile()) vm.$refs.floater.animate();
  });
});

// ===============================================
// Fade body in after document ready
// ============================================
document.addEventListener('DOMContentLoaded', () => {
  document.querySelector('#wrapper').style.opacity = 1;
});

// ===============================================
// Logo Fade on scroll
// ============================================
logoFade();
