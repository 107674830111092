<template lang="html">
  <!-- Root element of PhotoSwipe. Must have class pswp. -->
  <div class="pswp" tabindex="-1" role="dialog" aria-hidden="true">
    <!-- Background of PhotoSwipe.
    It's a separate element as animating opacity is faster than rgba(). -->
    <div class="pswp__bg"></div>

    <!-- Slides wrapper with overflow:hidden. -->
    <div class="pswp__scroll-wrap">

      <!-- Container that holds slides.
      PhotoSwipe keeps only 3 of them in the DOM to save memory.
      Don't modify these 3 pswp__item elements, data is added later on. -->
      <div class="pswp__container">
        <div class="pswp__item"></div>
        <div class="pswp__item"></div>
        <div class="pswp__item"></div>
      </div>

      <!-- Default (PhotoSwipeUI_Default) interface on top of sliding area. Can be changed. -->
      <div class="pswp__ui pswp__ui--hidden">
        <div class="pswp__top-bar">
          <!--  Controls are self-explanatory. Order can be changed. -->
          <div class="pswp__counter"></div>
          <button class="pswp__button pswp__button--close" title="Close (Esc)"></button>
          <!-- <button class="pswp__button pswp__button--share" title="Share"></button> -->
          <button class="pswp__button pswp__button--fs" title="Toggle fullscreen"></button>
          <button class="pswp__button pswp__button--zoom" title="Zoom in/out"></button>

          <!-- Preloader demo http://codepen.io/dimsemenov/pen/yyBWoR -->
          <!-- element will get class pswp__preloader--active when preloader is running -->
          <div class="pswp__preloader">
            <div class="pswp__preloader__icn">
              <div class="pswp__preloader__cut">
                <div class="pswp__preloader__donut"></div>
              </div>
            </div>
          </div>
        </div>

        <div class="pswp__share-modal pswp__share-modal--hidden pswp__single-tap">
          <div class="pswp__share-tooltip"></div>
        </div>

        <button class="pswp__button pswp__button--arrow--left" title="Previous (arrow left)">
        </button>

        <button class="pswp__button pswp__button--arrow--right" title="Next (arrow right)">
        </button>

        <div class="pswp__caption">
          <div class="pswp__caption__center"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PhotoSwipe from 'photoswipe';
import PhotoSwipeUI_Default  from 'photoswipe/src/js/ui/photoswipe-ui-default';

export default {
  name: 'PhotoSwipe',
  props: {
    selector: {
      type: String,
      required: true
    }
  },

  data(){
    return {
      items: [
        // {
        //   src: 'https://placekitten.com/600/400',
        //   w: 600,
        //   h: 400
        // },
        // {
        //   src: 'https://placekitten.com/1200/900',
        //   w: 1200,
        //   h: 900
        // }
      ],
      options: {
        history: false,
        maxSpreadZoom: 4
      }
    };
  },

  created(){
  },

  mounted(){
    // get galleries
    const galleries = document.querySelectorAll(this.selector);

    galleries.forEach((gallery, index) => {
      const images = gallery.querySelectorAll('img');

      // create thumbnails markup
      images.forEach(img => this.createThumbnails(gallery, img));

      // create items array
      this.items.push(this.createItemlist(images));

      // bind click events
      this.bindClickEvents(gallery, index);
    });

    // define thumbnail getter
    this.options.getThumbBoundsFn = index =>  {
      const thumbnail = galleries[this.currentGallery].querySelectorAll('img')[index];

      const pageYScroll = window.pageYOffset || document.documentElement.scrollTop;
      const rect = thumbnail.getBoundingClientRect();

      return {x:rect.left, y:rect.top + pageYScroll, w:rect.width};
    };
  },

  methods: {
    createThumbnails(gallery, img){
      const figureTag        = document.createElement('figure');
      const figureCaptionTag = document.createElement('figcaption');
      const anchorTag        = document.createElement('a');

      // append figure to image current position before appending the others
      img.parentNode.insertBefore(figureTag, img);

      // append image to anchor tag
      anchorTag.appendChild(img);
      // set href to image source
      anchorTag.setAttribute('href', img.getAttribute('src'));
      // set data-size attribute
      anchorTag.setAttribute('data-size', `${img.getAttribute('width')}x${img.getAttribute('height')}`);

      // create caption
      figureCaptionTag.innerHTML = img.getAttribute('alt');

      // append to figure
      figureTag.appendChild(anchorTag);
      figureTag.appendChild(figureCaptionTag);
    },

    createItemlist(images){
      let imageList = [];

      images.forEach(img => {
        const title  = img.getAttribute('alt');
        const source = img.getAttribute('src');
        const width  = img.getAttribute('width');
        const height = img.getAttribute('height');

        imageList.push({
          src: source,
          w: width,
          h: height,
          title: title
        });
      });

      return imageList;
    },

    openPhotoSwipe(){
      this.pwsp = new PhotoSwipe(this.$el, PhotoSwipeUI_Default, this.items[this.currentGallery], this.options);
      this.pwsp.init();
    },

    bindClickEvents(gallery, galleryIndex){
      const thumbnails = gallery.querySelectorAll('figure');

      thumbnails.forEach((thumb, index) => {
        thumb.addEventListener('click', e => {
          e = e || window.event;
          e.preventDefault ? e.preventDefault() : e.returnValue = false;

          this.options.index = index;
          this.currentGallery = galleryIndex;

          this.openPhotoSwipe();
        });
      });
    }
  }
};
</script>

<style lang="css">
</style>
