var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "modal--inner" },
    [
      _c(
        "div",
        { staticClass: "row no-gutters" },
        [
          _c(
            "div",
            {
              staticClass: "modal--back",
              class: {
                col: !_vm.isMobile,
                "is-arrow": !_vm.isMobile,
                "col-12": _vm.isMobile,
              },
            },
            [
              !_vm.isMobile
                ? _c(
                    "button",
                    {
                      on: {
                        click: function ($event) {
                          return _vm.$emit("close-modal")
                        },
                      },
                    },
                    [
                      _vm._v("\n        Go Back\n        "),
                      _c(
                        "svg",
                        {
                          attrs: {
                            xmlns: "http://www.w3.org/2000/svg",
                            viewBox: "0 0 261 57.535",
                          },
                        },
                        [
                          _c("path", {
                            attrs: {
                              "clip-rule": "evenodd",
                              fill: "none",
                              stroke: "#000",
                              "stroke-width": "5",
                              "stroke-miterlimit": "10",
                              d: "M63 55.768l-27-27 27-27",
                            },
                          }),
                          _vm._v(" "),
                          _c("path", {
                            attrs: {
                              "clip-rule": "evenodd",
                              fill: "none",
                              stroke: "#000",
                              "stroke-width": "5",
                              "stroke-miterlimit": "10",
                              d: "M36 28.768h225",
                            },
                          }),
                        ]
                      ),
                    ]
                  )
                : _c(
                    "StyledButton",
                    {
                      on: {
                        "button-click": function ($event) {
                          return _vm.$emit("close-modal")
                        },
                      },
                    },
                    [_vm._v("BACK")]
                  ),
            ],
            1
          ),
          _vm._v(" "),
          _c("TabSection", {
            staticClass: "col modal--content",
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (currentTab) {
                  return [
                    _vm.content.description
                      ? _c(
                          "tab",
                          _vm._b(
                            { attrs: { name: "Overview" } },
                            "tab",
                            currentTab,
                            false
                          ),
                          [
                            _c("div", { staticClass: "row no-gutters" }, [
                              _c(
                                "div",
                                { staticClass: "col-sm-6 modal--description" },
                                [
                                  _c("div", { staticClass: "block--logo" }, [
                                    _vm.content.logo
                                      ? _c("img", {
                                          attrs: {
                                            src: _vm.content.logo.url,
                                            alt: _vm.content.logo.alt,
                                          },
                                        })
                                      : _vm._e(),
                                  ]),
                                  _vm._v(" "),
                                  _c("div", {
                                    staticClass: "block--description",
                                    domProps: {
                                      innerHTML: _vm._s(
                                        _vm.content.description
                                      ),
                                    },
                                  }),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "col-sm-6 modal--image" },
                                [
                                  _c("div", { staticClass: "image--box" }, [
                                    _c("img", {
                                      attrs: {
                                        src: _vm.content.image.url,
                                        alt: _vm.content.image.alt,
                                      },
                                    }),
                                  ]),
                                ]
                              ),
                            ]),
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.content.gallery
                      ? _c(
                          "tab",
                          _vm._b(
                            { attrs: { name: "Gallery" } },
                            "tab",
                            currentTab,
                            false
                          ),
                          [
                            _c("div", {
                              staticClass: "image--gallery",
                              domProps: {
                                innerHTML: _vm._s(_vm.content.gallery),
                              },
                            }),
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.content.locationMap
                      ? _c(
                          "tab",
                          _vm._b(
                            { attrs: { name: "Location" } },
                            "tab",
                            currentTab,
                            false
                          ),
                          [
                            _c("div", {
                              staticClass: "image--gallery location-map",
                              domProps: {
                                innerHTML: _vm._s(_vm.content.locationMap),
                              },
                            }),
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.content.siteProgress
                      ? _c(
                          "tab",
                          _vm._b(
                            { attrs: { name: "Site Progress" } },
                            "tab",
                            currentTab,
                            false
                          ),
                          [
                            _c("div", {
                              staticClass: "image--gallery",
                              domProps: {
                                innerHTML: _vm._s(_vm.content.siteProgress),
                              },
                            }),
                          ]
                        )
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("PhotoSwipe", { attrs: { selector: ".image--gallery" } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }