<template lang="html">
  <div class="slide--thumb">
    <div class="slide--title">
      <h2 v-html="content.title"></h2>
    </div>

    <div class="slide--date">
      <h1>{{ content.date[1] }}</h1>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SlideThumb',
  props: ['content'],

  data(){
    return {
      window: window
    };
  }
};
</script>

<style lang="css">
</style>
