var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "floating--box" }, [
    _c("div", { staticClass: "floating--boxes" }),
    _vm._v(" "),
    _c("div", { staticClass: "floating--content" }, [
      _c("div", { staticClass: "content--wrapper" }, [
        _c("div", { staticClass: "content--inner" }, [
          _c("div", { staticClass: "content" }, [_vm._t("default")], 2),
        ]),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "floating--boxes" }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }