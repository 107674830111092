var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "sub--navigation" }, [
    _c(
      "ul",
      _vm._l(_vm.parsedItems, function (item) {
        return _c("li", {
          class: { "is-current": item.value == _vm.current },
          attrs: { "data-value": item.value },
          domProps: { innerHTML: _vm._s(item.name) },
          on: {
            click: function ($event) {
              return _vm.clickEvent($event, item)
            },
          },
        })
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }