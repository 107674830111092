var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "button" }, [
    _vm.href
      ? _c("a", {
          attrs: { href: _vm.href, target: _vm.target, rel: _vm.rel },
          on: {
            click: function ($event) {
              return _vm.$emit("button-click")
            },
          },
        })
      : _c("button", {
          attrs: { type: _vm.type },
          on: {
            click: function ($event) {
              return _vm.$emit("button-click")
            },
          },
        }),
    _vm._v(" "),
    _vm.text
      ? _c("span", { domProps: { innerHTML: _vm._s(_vm.text) } })
      : _c("span", [_vm._t("default")], 2),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }