<template lang="html">
  <div class="modal--inner">
    <div class="row no-gutters">
      <!-- Back Button -->
      <div class="modal--back" v-bind:class="{'col': !isMobile, 'is-arrow': !isMobile, 'col-12': isMobile}">
        <button v-on:click='$emit("close-modal")' v-if="!isMobile">
          Go Back
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 261 57.535">
            <path clip-rule="evenodd" fill="none" stroke="#000" stroke-width="5" stroke-miterlimit="10" d="M63 55.768l-27-27 27-27"/>
            <path clip-rule="evenodd" fill="none" stroke="#000" stroke-width="5" stroke-miterlimit="10" d="M36 28.768h225"/>
          </svg>
        </button>
        <StyledButton v-else v-on:button-click="$emit('close-modal')">BACK</StyledButton>
      </div>

      <!-- Tabbed Section -->
      <TabSection class="col modal--content">
        <template slot-scope="currentTab">
          <tab v-bind="currentTab" name="Overview" v-if="content.description">
            <div class="row no-gutters">
              <div class="col-sm-6 modal--description">
                <div class="block--logo">
                  <img v-if="content.logo" v-bind:src="content.logo.url" v-bind:alt="content.logo.alt">
                </div>
                <div class="block--description" v-html='content.description'></div>
              </div>
              <div class="col-sm-6 modal--image">
                <div class="image--box"><img v-bind:src="content.image.url" v-bind:alt="content.image.alt"></div>
              </div>
            </div>
          </tab>
          <tab v-bind="currentTab" name="Gallery" v-if="content.gallery">
            <div class="image--gallery" v-html='content.gallery'></div>
          </tab>
          <tab v-bind="currentTab" name="Location" v-if="content.locationMap">
            <div class="image--gallery location-map" v-html='content.locationMap'></div>
          </tab>
          <tab v-bind="currentTab" name="Site Progress" v-if="content.siteProgress">
            <div class="image--gallery" v-html='content.siteProgress'></div>
          </tab>
        </template>
      </TabSection>
    </div>

    <PhotoSwipe selector=".image--gallery" />
  </div>
</template>

<script>
import TabSection        from 'components/TabSection';
import Tab               from 'components/TabSection/Tab';

import SubNavigationList from 'components/SubNavigationList';
import StyledButton      from 'components/StyledButton';

import PhotoSwipe        from 'components/PhotoSwipe';
import Utils             from 'utils';

export default {
  name: 'SlideContent',
  components: { SubNavigationList, TabSection, Tab, PhotoSwipe, StyledButton },
  props: {
    content: {
      type: Object,
      required: true
    }
  },

  data(){
    return {
      isMobile: Utils.checkMobile()
    };
  }
};
</script>

<style lang="css">
</style>
