var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "tab--section" }, [
    _c(
      "div",
      { staticClass: "tab--nav" },
      [
        _c("SubNavigationList", {
          ref: "nav",
          attrs: { items: _vm.tabs },
          on: {
            change: function ($event) {
              _vm.currentTab = $event
            },
          },
        }),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "tab-list" },
      [_vm._t("default", null, { currentTab: _vm.currentTab })],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }