var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "modal--inner" },
    [
      _c("div", { staticClass: "row no-gutters" }, [
        _c(
          "div",
          {
            staticClass: "modal--back",
            class: {
              col: !_vm.isMobile,
              "is-arrow": !_vm.isMobile,
              "col-12": _vm.isMobile,
            },
          },
          [
            !_vm.isMobile
              ? _c(
                  "button",
                  {
                    on: {
                      click: function ($event) {
                        return _vm.$emit("close-modal")
                      },
                    },
                  },
                  [
                    _vm._v("\n        Go Back\n        "),
                    _c(
                      "svg",
                      {
                        attrs: {
                          xmlns: "http://www.w3.org/2000/svg",
                          viewBox: "0 0 261 57.535",
                        },
                      },
                      [
                        _c("path", {
                          attrs: {
                            "clip-rule": "evenodd",
                            fill: "none",
                            stroke: "#000",
                            "stroke-width": "5",
                            "stroke-miterlimit": "10",
                            d: "M63 55.768l-27-27 27-27",
                          },
                        }),
                        _vm._v(" "),
                        _c("path", {
                          attrs: {
                            "clip-rule": "evenodd",
                            fill: "none",
                            stroke: "#000",
                            "stroke-width": "5",
                            "stroke-miterlimit": "10",
                            d: "M36 28.768h225",
                          },
                        }),
                      ]
                    ),
                  ]
                )
              : _c(
                  "StyledButton",
                  {
                    on: {
                      "button-click": function ($event) {
                        return _vm.$emit("close-modal")
                      },
                    },
                  },
                  [_vm._v("BACK")]
                ),
          ],
          1
        ),
        _vm._v(" "),
        _c("div", {
          staticClass: "col modal--date",
          class: { invisible: _vm.isCareerPage },
          domProps: { innerHTML: _vm._s(_vm.formattedDate) },
        }),
        _vm._v(" "),
        _c("div", { staticClass: "col modal--content" }, [
          _c("h1", {
            staticClass: "block--title",
            domProps: { innerHTML: _vm._s(_vm.content.title) },
          }),
          _vm._v(" "),
          _c("div", {
            staticClass: "block--content",
            domProps: { innerHTML: _vm._s(_vm.content.content) },
          }),
        ]),
      ]),
      _vm._v(" "),
      _c("PhotoSwipe", { attrs: { selector: ".block--content" } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }