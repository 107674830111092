<template>
  <div class="tab--section">
    <div class="tab--nav">
      <SubNavigationList
        ref="nav"
        v-bind:items="tabs"
        v-on:change="currentTab = $event"
      />
    </div>

    <div class="tab-list">
      <slot v-bind:currentTab="currentTab"></slot>
    </div>
  </div>
</template>

<script>
import SubNavigationList from 'components/SubNavigationList';
import _kebabCase        from 'lodash/kebabCase';

export default {
  name: 'TabSection',
  components: { SubNavigationList },

  data(){
    return {
      tabs: [],

      isActive: false,
      currentTab: ''
    };
  },

  mounted(){
    // get list of tabs
    const tabs = this.$children.filter(child => child != this.$refs.nav);

    // create list of tab names
    tabs.forEach(tab => this.tabs.push(_kebabCase(tab.name)));

    // set first tab
    this.currentTab = this.tabs[0];
  }
};
</script>

<style lang="css">
</style>
