<template lang="html">
  <div class="video">
    <div class="video--box">
      <div class="video--thumbnail" v-if="!interacted" v-on:click="loadVideo" v-bind:style="backgroundImage">
        <p class="video--title" v-html="title"></p>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 185.865 185.865">
          <path d="M66.087 129.445l.215-73.097 80.784 36.441z"/>
          <circle fill="none" stroke="#000" stroke-width="8" stroke-miterlimit="10" cx="92.933" cy="92.933" r="87.933"/>
        </svg>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LazyVideo',

  props: {
    id: {
      type: String,
      required: true
    },
    title: {
      type: String
    }
  },

  data(){
    return {
      interacted: false,
      thumbnail: `http://img.youtube.com/vi/${this.id}/hqdefault.jpg`
    };
  },

  mounted(){

  },

  computed: {
    backgroundImage: function(){
      return `background-image: url(${this.thumbnail});`;
    }
  },

  methods: {
    loadVideo(){
      const iframe = document.createElement('iframe');
      iframe.setAttribute('frameborder', '0');
      iframe.setAttribute('allowfullscreen', '');
      iframe.setAttribute('allow', 'autoplay; encrypted-media');
      iframe.setAttribute('src', `https://www.youtube-nocookie.com/embed/${this.id}?rel=0&amp&autoplay=1`);

      this.$el.querySelector('.video--box').appendChild(iframe);
      this.interacted = true;
    }
  }
};
</script>

<style lang="css">
</style>
