var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "slide" }, [
    _c("div", { staticClass: "row no-gutters" }, [
      _c("div", { staticClass: "col-sm-4" }, [
        _c("div", { staticClass: "slide--header" }, [_vm._t("header")], 2),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "slide--content" },
          [
            _vm._t("content"),
            _vm._v(" "),
            _vm._l(_vm.button, function (b, index) {
              return _c(
                "StyledButton",
                {
                  key: index,
                  ref: "button",
                  refInFor: true,
                  attrs: {
                    href: b.href,
                    target: b.target,
                    rel: b.rel,
                    auto: false,
                    "gtag-click": b.tracker,
                  },
                },
                [_vm._v("\n          " + _vm._s(b.text) + "\n        ")]
              )
            }),
          ],
          2
        ),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "slide--background" }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }