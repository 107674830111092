var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "slide--thumb" }, [
    _c(
      "div",
      {
        staticClass: "slide--image",
        class: { "is-logo": !_vm.content.image && _vm.content.logo },
      },
      [
        _c("img", {
          attrs: {
            src: _vm.content.image
              ? _vm.content.image.url
              : _vm.content.logo
              ? _vm.content.logo.url
              : `${_vm.window._templateDir}/img/projects/trion.jpg`,
            alt: "",
          },
        }),
      ]
    ),
    _vm._v(" "),
    _c("div", { staticClass: "slide--title" }, [
      _c("h1", [_vm._v(_vm._s(_vm.content.title))]),
      _vm._v(" "),
      _c("p", [_vm._v("@ " + _vm._s(_vm.content.location))]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }