var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "carousel--track", on: { wheel: _vm.incrementScroll } },
    [
      !_vm.isMobile
        ? _c(
            "div",
            {
              staticClass: "scroll--prompt",
              class: { "is-hidden": _vm.disableScroll },
            },
            [_vm._m(0), _vm._v(" "), _vm._m(1)]
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "transitionGroup",
        {
          staticClass: "thumb--list",
          attrs: { name: "slide-thumb-anim", tag: "div", css: false },
          on: {
            "before-leave": _vm.beforeAnim,
            "before-enter": _vm.beforeAnim,
            enter: _vm.enter,
            leave: _vm.leave,
            "after-leave": _vm.afterAnim,
            "after-enter": _vm.afterAnim,
          },
        },
        _vm._l(_vm.slides, function (slide, index) {
          return slide.show
            ? _c("SlideThumb", {
                key: index,
                attrs: {
                  "data-index": index,
                  content: slide.content,
                  "gtag-click": `News & Events | Articles | ${slide.content.title}`,
                },
                nativeOn: {
                  mouseup: function ($event) {
                    return _vm.toggleModal(index)
                  },
                },
              })
            : _vm._e()
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", [
      _c("b", [_vm._v("Drag")]),
      _vm._v(" or "),
      _c("b", [_vm._v("scroll")]),
      _vm._v(" your mouse to navigate."),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "indicators" }, [
      _c("div", { staticClass: "indicator--icons grabbable" }),
      _vm._v(" "),
      _c("span", [_vm._v("/")]),
      _vm._v(" "),
      _c("div", { staticClass: "indicator--icons scroll" }, [
        _c("div", { staticClass: "wheel" }),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }