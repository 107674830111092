var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "input--group" }, [
    _c("label", { attrs: { for: "" } }, [_vm._v(_vm._s(_vm.label))]),
    _vm._v(" "),
    _c("span", { staticClass: "danger" }, [_vm._v(_vm._s(_vm.error))]),
    _vm._v(" "),
    _c(
      "select",
      {
        class: { "has-error": _vm.error, "has-value": _vm.value.length > 0 },
        attrs: { type: "select" },
        domProps: { value: _vm.value },
        on: {
          input: _vm.updateValue,
          change: _vm.updateValue,
          blur: function ($event) {
            return _vm.$emit("blur")
          },
          focus: function ($event) {
            return _vm.$emit("focus")
          },
        },
      },
      [_vm._t("default")],
      2
    ),
    _vm._v(" "),
    _c("div", { staticClass: "border" }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }