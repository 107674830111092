<template lang="html">
  <div class="navigation">
    <div class="navigation--links">
      <ul>
        <li><a gtag-click="Navigation Menu | Main Links | Button : Home" href="/?skip">HOME</a></li>
        <li><a gtag-click="Navigation Menu | Main Links | Button : About" href="/about-us">ABOUT</a></li>
        <li><a gtag-click="Navigation Menu | Main Links | Button : Collection" href="/collection">COLLECTION</a></li>
        <li><a gtag-click="Navigation Menu | Main Links | Button : News &amp; Events" href="/news-events">NEWS &amp; EVENTS</a></li>
        <li><a gtag-click="Navigation Menu | Main Links | Button : CSR" href="/csr">CSR</a></li>
        <li><a gtag-click="Navigation Menu | Main Links | Button : Career" href="/career">CAREER</a></li>
        <li><a gtag-click="Navigation Menu | Main Links | Button : Contact" href="/contact-us">CONTACT</a></li>
        <li><a gtag-click="Navigation Menu | Main Links | Button : Registration" href="/registration">REGISTRATION</a></li>
      </ul>

      <ul class="sm--icons">
        <li class="icon-fb">
          <a gtag-click="Navigation Menu | Social Media | Button : Facebook" href="https://www.facebook.com/BinastraLand" target="_blank" rel="noopener noreferrer">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 264 512">
              <path d="M76.7 512V283H0v-91h76.7v-71.7C76.7 42.4 124.3 0 193.8 0c33.3 0 61.9 2.5 70.2 3.6V85h-48.2c-37.8 0-45.1 18-45.1 44.3V192H256l-11.7 91h-73.6v229"/>
            </svg>
          </a>
        </li>
        <li class="icon-insta">
          <a gtag-click="Navigation Menu | Social Media | Button : Instagram" href="https://www.instagram.com/binastraland/" target="_blank" rel="noopener noreferrer">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
              <path d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z"/>
            </svg>
          </a>
        </li>
        <li class="icon-youtube">
          <a gtag-click="Navigation Menu | Social Media | Button : YouTube" href="https://www.youtube.com/user/BinastraGroup" target="_blank" rel="noopener">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
              <path d="M549.655 124.083c-6.281-23.65-24.787-42.276-48.284-48.597C458.781 64 288 64 288 64S117.22 64 74.629 75.486c-23.497 6.322-42.003 24.947-48.284 48.597-11.412 42.867-11.412 132.305-11.412 132.305s0 89.438 11.412 132.305c6.281 23.65 24.787 41.5 48.284 47.821C117.22 448 288 448 288 448s170.78 0 213.371-11.486c23.497-6.321 42.003-24.171 48.284-47.821 11.412-42.867 11.412-132.305 11.412-132.305s0-89.438-11.412-132.305zm-317.51 213.508V175.185l142.739 81.205-142.739 81.201z"/>
            </svg>
          </a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import _throttle from 'lodash/throttle';

import SVG   from 'svg.js';
import anime from 'animejs';

import Utils from 'utils';

export default {
  name: 'HeaderNavigation',

  props: {
    navActive: {required: true}
  },

  mounted(){
    this.draw = SVG(this.$el);
    this.polyFill = this.draw.polygon();
    this.polyFill.fill('#000');

    this.initSVGBackground();

    window.addEventListener('resize', _throttle(this.initSVGBackground, 200));
  },

  watch: {
    navActive: function(){
      this.$nextTick(()=>{
        this.animateBackground();
        this.animateLinks();
      });
    }
  },

  methods: {
    initSVGBackground(){
      // get parent
      const parent = this.$el;

      // get size of parent
      this.width  = parent.getBoundingClientRect().width;
      this.height = parent.getBoundingClientRect().height;

      // define shorter variables
      const w = this.width;
      const h = this.height;

      // define active/inactive state points
      this.inActivePoints = `${w} 0, ${w} 0, ${w} ${h}, ${w} ${h}`;
      // this.activePoints   = `0 0, ${w} 0, ${w} ${h}, ${w*0.2} ${h}`;

      this.activePoints = Utils.checkMobile() ?
                          `${w*-0.2} 0, ${w} 0, ${w} ${h}, 0        ${h}` :
                          `0         0, ${w} 0, ${w} ${h}, ${w*0.2} ${h}`;

      // set svg size
      this.draw.size(w, h);

      // create black poly object
      if(this.navActive) this.polyFill.plot(this.activePoints);
      else this.polyFill.plot(this.inActivePoints);
    },

    animateBackground(){
      anime.remove(this.polyFill.node);

      anime({
        targets: this.polyFill.node,
        points: ()=>{
          if(this.navActive) return this.activePoints;
          else return this.inActivePoints;
        },
        duration: 550,
        delay: () =>{
          if(!this.navActive) return 180;
          else return 0;
        },
        easing: 'easeInOutQuart'
      });
    },

    animateLinks(){
      anime.remove('.navigation--links li');

      anime({
        targets: '.navigation--links li',
        translateX: t =>{
          if(this.navActive){
            // check if its first run, and use explicit 'from to' to get around a bug
            if(t.getAttribute('style') == null) return ['100%', '0%'];
            else return '0%';
          }
          else{
            return '100%';
          }
        },

        opacity: () =>{
          if(this.navActive) return 1;
          else return 0;
        },

        duration: 550,
        easing: 'easeInOutQuart',
        delay: (total, index) => index*30
      });
    }
  }
};
</script>

<style lang="css">
</style>
