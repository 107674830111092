var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "news--carousel" },
    [
      _c(
        "div",
        { staticClass: "carousel--header" },
        [
          _c("h1", [_vm._v("NEWS & EVENTS")]),
          _vm._v(" "),
          _c("SubNavigationList", {
            staticClass: "carousel--filter",
            attrs: { items: _vm.years },
            on: {
              change: function ($event) {
                return _vm.filterSlides($event)
              },
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("Carousel", {
        attrs: { slides: _vm.slides },
        on: {
          "open-modal": function ($event) {
            return _vm.toggleModal($event)
          },
        },
      }),
      _vm._v(" "),
      _c(
        "transition",
        {
          attrs: { css: false },
          on: {
            "before-enter": _vm.beforeEnter,
            enter: _vm.toggleAnimation,
            leave: _vm.toggleAnimation,
            "after-leave": _vm.afterLeave,
          },
        },
        [
          _vm.currentSlide !== null
            ? _c(
                "div",
                { staticClass: "carousel--modal" },
                [
                  _c("SlideContent", {
                    attrs: { content: _vm.currentSlide },
                    on: { "close-modal": _vm.toggleModal },
                  }),
                ],
                1
              )
            : _vm._e(),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }