<template lang="html">
  <div class="checkbox--group">
    <p v-if="set">{{ set }}</p>
    <span v-if="set" class="danger">{{ error ? 'Please Select at least one.' : null }}</span>
    <span v-else class="danger">{{ error }}</span>

    <div class="checkbox--set" v-if="!checkboxes">
      <input
        type="checkbox"
        v-bind:name="label"
        v-bind:id="kebabCase(label)"
        v-on:input="updateValue"
        v-on:change="updateValue"
        v-on:blur="$emit('blur')"
        v-on:focus="$emit('focus')"
      >
      <div class="custom-checkbox"></div>
      <label v-bind:for="kebabCase(label)"><slot></slot></label>
    </div>
    <div class="checkbox--set" v-else v-for="(label, index) in checkboxes">
      <input
        type="checkbox"
        v-bind:name="label"
        v-bind:id="kebabCase(label)"
        v-on:input="updateValue"
        v-on:change="updateValue"
        v-on:blur="$emit('blur')"
        v-on:focus="$emit('focus')"
      >
      <div class="custom-checkbox"></div>
      <label v-bind:for="kebabCase(label)">{{ label }}</label>
    </div>
  </div>
</template>

<script>
import _kebabCase from 'lodash/kebabCase';

export default {
  name: 'Checkbox',
  props: {
    label:      {type: String},
    set:        {type: String},
    checkboxes: {type: Array},
    error:      {type: [Boolean, String]},
    value:      {type: [Boolean, Array]}
  },

  $_veeValidate: {
    rejectsFalse: true,

    name(){
      return this.label || this.set;
    },

    value(){
      return this.value;
    }
  },

  methods: {
    updateValue(){
      const values = this.checkboxes ? this.getMultipleCheckbox() : this.getSingleCheckbox();
      this.$emit('input', values);
    },

    getSingleCheckbox(){
      const input = this.$el.querySelector('input');

      return input.checked;
    },

    getMultipleCheckbox(){
      let values   = [];
      const inputs = this.$el.querySelectorAll('input');

      inputs.forEach(input => {
        if(input.checked) values.push(input.getAttribute('name'));
      });

      return values;
    },

    kebabCase(string){
      return _kebabCase(string);
    }
  }
};
</script>

<style lang="css">
</style>
