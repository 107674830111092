<template lang="html">
  <div class="input--group">
    <label for="">{{ label }}</label>
    <span class="danger">{{ error }}</span>
    <select
      type="select"
      v-bind:value="value"
      v-on:input="updateValue"
      v-on:change="updateValue"
      v-on:blur="$emit('blur')"
      v-on:focus="$emit('focus')"
      v-bind:class="{'has-error': error, 'has-value': value.length > 0}"
    >
      <slot></slot>
    </select>
    <div class="border"></div>
  </div>
</template>

<script>
export default {
  name: 'Select',

  props: {
    label: {type: String},
    value: {type: String},
    error: {type: String}
  },

  $_veeValidate: {
    name(){
      return this.label;
    },

    value(){
      return this.value;
    }
  },

  methods: {
    onInput(e){
      this.$emit('input', e.target.value);
    },
    updateValue(e){
      this.$emit('input', e.target.value);
    }
  }
};
</script>

<style lang="css">
</style>
