<template lang="html">
  <div class="carousel--track" v-on:wheel="incrementScroll">
    <div class="scroll--prompt" v-if="!isMobile" v-bind:class="{ 'is-hidden': disableScroll }">
      <p><b>Drag</b> or <b>scroll</b> your mouse to navigate.</p>
      <div class="indicators">
        <div class="indicator--icons grabbable"></div>
        <span>/</span>
        <div class="indicator--icons scroll">
          <div class="wheel"></div>
        </div>
      </div>
    </div>

    <transitionGroup
      name="slide-thumb-anim"
      tag="div"
      class="thumb--list"
      v-bind:css="false"
      v-on:before-leave="beforeAnim"
      v-on:before-enter="beforeAnim"
      v-on:enter="enter"
      v-on:leave="leave"
      v-on:after-leave="afterAnim"
      v-on:after-enter="afterAnim"
      >
      <SlideThumb
        v-for='(slide, index) in slides'
        v-if='slide.show'
        v-bind:key='index'
        v-bind:data-index='index'
        v-bind:content='slide.content'
        v-on:mouseup.native='toggleModal(index)'
        v-bind:gtag-click="`News & Events | Articles | ${slide.content.title}`"
      />
    </transitionGroup>
  </div>
</template>

<script>
import SlideThumb from 'components/NewsCarousel/SlideThumb';

import anime      from 'animejs';
import Impetus    from 'impetus';
import _throttle  from 'lodash/throttle';

import Utils      from 'utils';

export default {
  name: 'Carousel',
  components: {SlideThumb},

  props: {
    slides: {required: true}
  },

  data(){
    return {
      hasActiveSlide: false,
      currentSlide: '',

      trackWidth: 0,
      disableScroll: false,

      moveX: 0,
      maxMovement: 0,
      minMovement: 0,

      isMobile: Utils.checkMobile()
    };
  },

  watch: {
    moveX: function(value){
      if(this.scrolling) return;

      // clear timer to reset drag state
      clearTimeout(this.timeout);
      // set is dragging to true as long as value is being updated
      this.isDragging = true;

      this.$el.style.webkitTransform = `translateX(${value}px)`;
      this.$el.style.mozTransform = `translateX(${value}px)`;
      this.$el.style.msTransform = `translateX(${value}px)`;
      this.$el.style.oTransform = `translateX(${value}px)`;

      // set is dragging to false 10ms after values stop updating
      this.timeout = setTimeout(()=> this.isDragging = false, 50);
    }
  },

  mounted(){
    if(this.isMobile) {
      if(document.readyState != 'complete') document.addEventListener('DOMContentLoaded', () => this.setTopOffset());
      else this.setTopOffset();
      return;
    }

    if(document.readyState != 'complete') window.addEventListener('load', () => this.initCarouselTrack());
    else this.initCarouselTrack();

    window.addEventListener('resize', _throttle(this.initCarouselTrack, 200));
  },

  methods: {
    setTopOffset(){
      const thumbs  = this.$el.querySelectorAll('.slide--thumb');
      thumbs.forEach(thumb => thumb.style.top = `${thumb.offsetTop}px`);
    },

    incrementScroll(e){
      if(Utils.checkMobile()) return;

      // return if is snapping back
      if(this.queueSnapback) return;

      // remove afrom animation queue
      anime.remove(this.$el);

      // get new value
      const newVal = -e.deltaY*2 + this.moveX;

      // check if out of bounds
      const outOfBoundsMin = newVal > this.minMovement;
      const outOfBoundsMax = newVal < this.maxMovement;

      // compute values before passing to animejs
      const animVal = (outOfBoundsMin || outOfBoundsMax) ? -e.deltaY + this.moveX : -e.deltaY*2 + this.moveX;

      // animate scrolling
      anime({
        targets: this.$el,
        translateX: animVal,
        duration: 700,
        easing: 'easeOutQuart',

        // set state to is scrolling
        begin: () => this.scrolling = true,

        update: () => {
          // check if out of bounds, and if yes, snapback to bounds
          if(outOfBoundsMin){
            this.snapBack(this.minMovement);
            return;
          }
          else if(outOfBoundsMax){
            this.snapBack(this.maxMovement);
            return;
          }

          // update position variables
          this.impetus.setValues(this.moveX, 0);
          this.moveX = animVal;
        },

        // set is scrolling to false
        complete: () => this.scrolling = false
      });
    },

    snapBack(value){
      // disable scrolling
      this.queueSnapback = true;
      // remove from animation queue
      anime.remove(this.$el);

      // snapback
      anime({
        targets: this.$el,
        translateX: value,

        duration: 700,
        easing: 'easeOutQuart',
        complete: () => {
          // reset variables
          this.queueSnapback = false;
          this.scrolling = false;

          // update position variables
          this.impetus.setValues(this.moveX, 0);
          this.moveX = value;
        }
      });
    },

    initCarouselTrack(){
      if(this.isMobile) return;

      if(this.impetus) this.impetus.destroy();

      // get direct childrens
      this.childrens  = this.$el.querySelectorAll('.scroll--prompt, .slide--thumb');

      // reset track to default
      this.disableScroll = false;
      this.moveX      = 0;
      this.trackWidth = 0;

      // set margin
      this.margin = window.innerWidth*0.03;

      this.margin = Utils.checkMobile() ?
                    window.innerWidth*0.05 :
                    window.innerWidth*0.03;

      // calculate total width of carousel
      this.childrens.forEach((child, index) => {
        if(index == 0) this.trackWidth += child.clientWidth;
        else this.trackWidth += child.clientWidth + this.margin*2;

        // skip the first, as its the arrow
        if(index == 0) return;

        // set left position
        child.style.left = `${child.offsetLeft}px`;
      });

      // set track width
      this.$el.style.width = `${this.trackWidth}px`;

      if(this.trackWidth + (this.margin*2) < window.innerWidth){
        this.disableScroll = true;
        return;
      }

      // set min and max movement of carousel
      this.maxMovement = -((window.innerWidth*0.07*2) + this.trackWidth - window.innerWidth);
      this.minMovement = 0;

      // set min-height of scroll prompt
      this.$el.querySelector('.scroll--prompt').style.minHeight = '';
      this.$el.querySelector('.scroll--prompt').style.minHeight = `${this.$el.offsetHeight}px`;

      // Init Impetus
      this.impetus = new Impetus({
        source: this.$el,
        bounce: true,
        boundX: [this.maxMovement, this.minMovement],
        update: x => this.moveX = x
      });
    },

    toggleModal(index){
      // return if is dragging
      if(this.isDragging) return;

      // emit open modal to open modal
      this.$emit('open-modal', index);
    },

    // ===============================
    // Animations
    // ===============================
    beforeAnim(){
      if(this.isMobile) return;

      anime({
        targets: this.$el,
        translateX: 0,

        duration: 650,
        easing: 'easeInOutQuart'
      });
    },

    enter(el, done){
      el.classList.add('is-animating');

      anime.remove(el);

      anime({
        targets: el,
        translateY: this.isMobile ? null : ['30%', 0],
        opacity: [0, 1],

        easing: 'easeOutQuart',
        duration: 450,

        complete: ()=>{
          done();
          el.classList.remove('is-animating');
        }
      });
    },

    leave(el, done){
      el.style.position = 'absolute';
      el.classList.add('is-animating');

      anime.remove(el);

      anime({
        targets: el,
        translateY: this.isMobile ? null : '30%',
        width: this.isMobile ? null : 0,
        margin: this.isMobile ? null : 0,

        height: this.isMobile ? 0 : null,
        opacity: 0,

        easing: 'easeOutQuart',
        duration: 450,

        complete: done
      });
    },

    afterAnim(){
      if(this.isMobile){
        this.setTopOffset();
        return;
      }

      this.initCarouselTrack();
      this.$el.querySelectorAll('.slide--thumb').forEach(thumb=> thumb.style.left = `${thumb.offsetLeft}px`);
    }
  }
};
</script>
