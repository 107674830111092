var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "video" }, [
    _c("div", { staticClass: "video--box" }, [
      !_vm.interacted
        ? _c(
            "div",
            {
              staticClass: "video--thumbnail",
              style: _vm.backgroundImage,
              on: { click: _vm.loadVideo },
            },
            [
              _c("p", {
                staticClass: "video--title",
                domProps: { innerHTML: _vm._s(_vm.title) },
              }),
              _vm._v(" "),
              _c(
                "svg",
                {
                  attrs: {
                    xmlns: "http://www.w3.org/2000/svg",
                    viewBox: "0 0 185.865 185.865",
                  },
                },
                [
                  _c("path", {
                    attrs: { d: "M66.087 129.445l.215-73.097 80.784 36.441z" },
                  }),
                  _vm._v(" "),
                  _c("circle", {
                    attrs: {
                      fill: "none",
                      stroke: "#000",
                      "stroke-width": "8",
                      "stroke-miterlimit": "10",
                      cx: "92.933",
                      cy: "92.933",
                      r: "87.933",
                    },
                  }),
                ]
              ),
            ]
          )
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }