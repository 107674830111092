var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "carousel--wrapper" }, [
    _c(
      "div",
      { staticClass: "carousel" },
      [
        _c(
          "Slide",
          { ref: "slide1" },
          [_c("template", { slot: "content" }, [_c("div")])],
          2
        ),
        _vm._v(" "),
        _c(
          "Slide",
          {
            ref: "slide2",
            attrs: {
              button: [
                {
                  text: "BOOK NOW",
                  href: "//all.accor.com/a545",
                  target: "_blank",
                  tracker:
                    "Home Carousel | Slide : Mercure Hotels Kuala Lumpur Trion | Button : Book Now",
                },
              ],
            },
          },
          [
            _c("template", { slot: "content" }, [
              _c("div", [
                _c("img", {
                  staticStyle: { "max-width": "400px", height: "auto" },
                  attrs: {
                    src: `${_vm.window._templateDir}/img/binastra-hotel-text.png`,
                    alt: "",
                  },
                }),
              ]),
            ]),
          ],
          2
        ),
        _vm._v(" "),
        _c(
          "Slide",
          { ref: "slide3" },
          [
            _c("template", { slot: "content" }, [
              _c("div", [
                _c("img", {
                  staticStyle: { "max-width": "400px", height: "auto" },
                  attrs: {
                    src: `${_vm.window._templateDir}/img/trion-text-2.png`,
                    alt: "",
                  },
                }),
              ]),
            ]),
          ],
          2
        ),
        _vm._v(" "),
        _c(
          "Slide",
          { ref: "slide4" },
          [
            _c("template", { slot: "content" }, [
              _c("div", [
                _c("img", {
                  staticStyle: { "max-width": "400px", height: "auto" },
                  attrs: {
                    src: `${_vm.window._templateDir}/img/binastra-retails-text.png`,
                    alt: "",
                  },
                }),
              ]),
            ]),
          ],
          2
        ),
        _vm._v(" "),
        _c(
          "Slide",
          { ref: "slide5" },
          [
            _c("template", { slot: "content" }, [
              _c("div", [
                _c("img", {
                  staticStyle: { "max-width": "400px", height: "auto" },
                  attrs: {
                    src: `${_vm.window._templateDir}/img/binastra-BBP2-text.png`,
                    alt: "",
                  },
                }),
              ]),
            ]),
          ],
          2
        ),
        _vm._v(" "),
        _c(
          "Slide",
          { ref: "slide6" },
          [
            _c("template", { slot: "content" }, [
              _c("div", [
                _c("img", {
                  staticStyle: { "max-width": "400px", height: "auto" },
                  attrs: {
                    src: `${_vm.window._templateDir}/img/binastra-BBP1-text.png`,
                    alt: "",
                  },
                }),
              ]),
            ]),
          ],
          2
        ),
        _vm._v(" "),
        _c(
          "Slide",
          {
            ref: "slide7",
            attrs: {
              button: [
                {
                  text: "OPEN FOR SALES",
                  href: "//suriagarden.com.my/",
                  target: "_blank",
                  tracker:
                    "Home Carousel | Slide : Suria Garden | Button : Register Now",
                },
              ],
            },
          },
          [
            _c("template", { slot: "content" }, [
              _c("div", [
                _c("img", {
                  staticStyle: { "max-width": "400px", height: "auto" },
                  attrs: {
                    src: `${_vm.window._templateDir}/img/binastra-SuriaGarden-text.png`,
                    alt: "",
                  },
                }),
              ]),
            ]),
          ],
          2
        ),
        _vm._v(" "),
        _c(
          "Slide",
          {
            ref: "slide8",
            attrs: {
              button: [
                {
                  text: "ABOUT US",
                  href: "/about-us",
                  tracker:
                    "Home Carousel | Slide : Assurance in Workmanship | Button : About Us",
                },
              ],
            },
          },
          [
            _c("template", { slot: "header" }, [
              _c("h1", [_vm._v("ASSURANCE")]),
              _vm._v(" "),
              _c("h1", [_vm._v("IN WORKMANSHIP")]),
            ]),
            _vm._v(" "),
            _c("template", { slot: "content" }, [
              _c("p", [
                _vm._v(
                  "We take pride in not just making you a house, but a better home to begin life at. From the finest details\n          to the bigger picture, we craft only the best."
                ),
              ]),
            ]),
          ],
          2
        ),
        _vm._v(" "),
        _c(
          "Slide",
          {
            ref: "slide9",
            attrs: {
              button: [
                {
                  text: "ABOUT US",
                  href: "/about-us",
                  tracker:
                    "Home Carousel | Slide : Assurance in Quality | Button : About Us",
                },
              ],
            },
          },
          [
            _c("template", { slot: "header" }, [
              _c("h1", [_vm._v("ASSURANCE")]),
              _vm._v(" "),
              _c("h1", [_vm._v("IN QUALITY")]),
            ]),
            _vm._v(" "),
            _c("template", { slot: "content" }, [
              _c("p", [
                _vm._v(
                  "All deliverance from the ground up is taken with the highest quality so that each project resonates its own\n          character, along with a touch of class and a dash of virtue."
                ),
              ]),
            ]),
          ],
          2
        ),
        _vm._v(" "),
        _c(
          "Slide",
          {
            ref: "slide10",
            attrs: {
              button: [
                {
                  text: "ABOUT US",
                  href: "/about-us",
                  tracker:
                    "Home Carousel | Slide : Timely Delivery | Button : About Us",
                },
              ],
            },
          },
          [
            _c("template", { slot: "header" }, [
              _c("h1", [_vm._v("ACHIEVING")]),
              _vm._v(" "),
              _c("h1", [_vm._v("GREATER EXCELLENCE")]),
            ]),
            _vm._v(" "),
            _c("template", { slot: "content" }, [
              _c("p", [
                _vm._v(
                  "More than just a multi-award-winning developer, we’re constantly improving based on our many decades of\n          experience and ever-expanding list of projects, to achieve new heights."
                ),
              ]),
            ]),
          ],
          2
        ),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "carousel--pagination" },
      _vm._l(_vm.totalSlides, function (slide, index) {
        return _c(
          "div",
          {
            key: slide.id,
            class: { "is-current": index == 0 },
            attrs: {
              "data-section-index": index,
              "gtag-click": `Home Carousel | Pagination | Slide : ${index}`,
            },
            on: {
              click: function ($event) {
                return _vm.changeIndex(index)
              },
            },
          },
          [_c("div")]
        )
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }