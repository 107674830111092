<template lang="html">
  <div class="floating--box">
    <div class="floating--boxes"></div>
    <div class="floating--content">
      <div class="content--wrapper">
        <div class="content--inner">
          <div class="content">
            <slot></slot>
          </div>
        </div>
      </div>
    </div>
    <div class="floating--boxes"></div>
  </div>
</template>

<script>
import anime from 'animejs';

import Utils from 'utils';

export default {
  name: 'FloatingContent',
  props: {
    auto: {
      type: Boolean,
      default: true
    }
  },

  mounted(){
    if(Utils.checkMobile()) return;
    if(this.auto) this.animate();
  },

  methods: {
    animate(){
      anime({
        targets: '.floating--boxes',
        width: [0, '16vw'],
        opacity: [0, 0.4],
        duration: 700,
        easing: 'easeOutQuart',

        delay: (t, i) => i*2*200 + 800
      });

      anime({
        targets: '.floating--content .content--wrapper',
        translateX: ['50%', 0],
        opacity: [0, 1],
        duration: 700,
        easing: 'easeOutQuart',

        delay: 1000
      });
    }
  }
};
</script>

<style lang="css">
</style>
