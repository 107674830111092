var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "checkbox--group" },
    [
      _vm.set ? _c("p", [_vm._v(_vm._s(_vm.set))]) : _vm._e(),
      _vm._v(" "),
      _vm.set
        ? _c("span", { staticClass: "danger" }, [
            _vm._v(_vm._s(_vm.error ? "Please Select at least one." : null)),
          ])
        : _c("span", { staticClass: "danger" }, [_vm._v(_vm._s(_vm.error))]),
      _vm._v(" "),
      !_vm.checkboxes
        ? _c("div", { staticClass: "checkbox--set" }, [
            _c("input", {
              attrs: {
                type: "checkbox",
                name: _vm.label,
                id: _vm.kebabCase(_vm.label),
              },
              on: {
                input: _vm.updateValue,
                change: _vm.updateValue,
                blur: function ($event) {
                  return _vm.$emit("blur")
                },
                focus: function ($event) {
                  return _vm.$emit("focus")
                },
              },
            }),
            _vm._v(" "),
            _c("div", { staticClass: "custom-checkbox" }),
            _vm._v(" "),
            _c(
              "label",
              { attrs: { for: _vm.kebabCase(_vm.label) } },
              [_vm._t("default")],
              2
            ),
          ])
        : _vm._l(_vm.checkboxes, function (label, index) {
            return _c("div", { staticClass: "checkbox--set" }, [
              _c("input", {
                attrs: {
                  type: "checkbox",
                  name: label,
                  id: _vm.kebabCase(label),
                },
                on: {
                  input: _vm.updateValue,
                  change: _vm.updateValue,
                  blur: function ($event) {
                    return _vm.$emit("blur")
                  },
                  focus: function ($event) {
                    return _vm.$emit("focus")
                  },
                },
              }),
              _vm._v(" "),
              _c("div", { staticClass: "custom-checkbox" }),
              _vm._v(" "),
              _c("label", { attrs: { for: _vm.kebabCase(label) } }, [
                _vm._v(_vm._s(label)),
              ]),
            ])
          }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }