<template lang="html">
  <div class="modal--inner">
    <div class="row no-gutters">
      <!-- Back Button -->
      <div class="modal--back" v-bind:class="{'col': !isMobile, 'is-arrow': !isMobile, 'col-12': isMobile}">
        <button v-on:click='$emit("close-modal")' v-if="!isMobile">
          Go Back
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 261 57.535">
            <path clip-rule="evenodd" fill="none" stroke="#000" stroke-width="5" stroke-miterlimit="10" d="M63 55.768l-27-27 27-27"/>
            <path clip-rule="evenodd" fill="none" stroke="#000" stroke-width="5" stroke-miterlimit="10" d="M36 28.768h225"/>
          </svg>
        </button>
        <StyledButton v-else v-on:button-click="$emit('close-modal')">BACK</StyledButton>
      </div>

      <div class="col modal--date" v-html="formattedDate" v-bind:class="{'invisible': isCareerPage}"></div>

      <!-- Content -->
      <div class="col modal--content">
        <h1 class="block--title" v-html="content.title"></h1>
        <div class="block--content" v-html="content.content"></div>
      </div>
    </div>

    <PhotoSwipe selector=".block--content" />
  </div>
</template>

<script>
import SubNavigationList from 'components/SubNavigationList';
import StyledButton      from 'components/StyledButton';

import PhotoSwipe        from 'components/PhotoSwipe';
import Utils             from 'utils';

export default {
  name: 'SlideContent',
  components: { SubNavigationList, PhotoSwipe, StyledButton },
  props: {
    content: {
      type: Object,
      required: true
    }
  },

  data(){
    let isCareerPage = false
    Utils.pageRunner('career', () => {
      isCareerPage = true
    })
    return {
      isMobile: Utils.checkMobile(),
      isCareerPage: isCareerPage
    };
  },

  computed: {
    formattedDate: function(){
      const date = this.content.date[1].split(' ');

      return `
      <span>${date[1]}</span>
      <span>${date[0]}</span>
      <span>${date[2]}</span>
      `;
    }
  }
};
</script>

<style lang="css">
</style>
