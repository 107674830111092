<template lang="html">
  <form v-on:submit.prevent="validateFields" method="get">
    <div class="row">
      <div class="col-sm-6">
        <h2>CONTACT INFORMATION</h2>
        <div class="row">
          <div class="col-sm-6">
            <Select
              label="Salutation"
              v-model="formFields.salutation"
              v-validate="'required'"
              :error="errors.first('Salutation')"
            >
              <option value=""></option>
              <option value="Tan Sri">Tan Sri</option>
              <option value="Puan Sri">Puan Sri</option>
              <option value="Dato Seri">Dato Seri</option>
              <option value="Datin Seri">Datin Seri</option>
              <option value="Dato">Dato</option>
              <option value="Datin">Datin</option>
              <option value="Mr">Mr</option>
              <option value="Mrs">Mrs</option>
              <option value="Ms">Ms</option>
              <option value="Dr">Dr</option>
              <option value="Prof">Prof</option>
            </Select>
          </div>
          <div class="col-sm-6">
            <Input
              label="Name"
              type="text"
              v-model="formFields.name"
              v-validate="'required'"
              :error="errors.first('Name')"
            />
          </div>
        </div>
        <div class="row">
          <div class="col-sm-6">
            <Input
            label="Email"
            type="email"
            v-model="formFields.email"
            v-validate="'required|email'"
            :error="errors.first('Email')"
            />
          </div>
          <div class="col-sm-6">
            <Select
              label="Age"
              v-model="formFields.age"
              v-validate="'required'"
              :error="errors.first('Age')"
            >
              <option value=""></option>
              <option value="< 18"> Under 18</option>
              <option value="18 - 21"> 18 - 21</option>
              <option value="21 - 30"> 21 - 30</option>
              <option value="31 - 40"> 31 - 40</option>
              <option value="41 - 50"> 41 - 50</option>
              <option value="51 - 60"> 51 - 60</option>
              <option value="61 - 70"> 61 - 70</option>
              <option value="> 70"> Above 70</option>
            </Select>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <Input
            label="Contact Number"
            type="text"
            v-model="formFields.number"
            v-validate="'required|numeric'"
            :error="errors.first('Contact Number')"
            />
          </div>
        </div>
      </div>

      <div class="col-sm-5 offset-sm-1">
        <h2><br></h2>
        <Checkbox
          set="Interested Property"
          v-model="formFields.interestedProperties"
          :checkboxes="[
            'Binastra Business Park @ Pulau Meranti',
            'Binastra Business Park @ Pulau Meranti 2',
            'Binastra Business Park @ Alam Damai',
            'Binastra Cochrane Park 1',
            'Binastra Cochrane Park 2',
          ]"
          v-validate="'required'"
          :error="errors.has('Interested Property')"
        />
      </div>

      <div class="col-sm-8">
        <p>(Note: all fields are mandatory)</p>
        <Checkbox
          label="Terms and Conditions"
          v-model="formFields.tac"
          v-validate="'required'"
          :error="errors.first('Terms and Conditions')"
        >
          By submitting this form, you hereby agree that Binastra Land may collect, obtain, store and process your personal data that you provide in this form for the purpose of receiving updates, news, promotional and marketing mails or materials from Binastra Land.
        </Checkbox>
        <styled-button v-bind:text="buttonMsg"></styled-button>
      </div>
    </div>

    <transition
      v-bind:css="false"
      v-on:enter="enter"
      v-on:leave="leave"
    >
      <div class="modal--form" v-if="modalOpen">
        <div class="modal--inner">
          <h1>THANK YOU FOR REGISTERING WITH US.</h1>
          <p>We will get back to you soon.</p>
          <StyledButton v-on:button-click="modalClose">CLOSE</StyledButton>
        </div>
      </div>
    </transition>
  </form>
</template>

<script>
import StyledButton  from 'components/StyledButton';

import Checkbox      from 'components/FormFields/Checkbox';
import TextArea      from 'components/FormFields/TextArea';
import Select        from 'components/FormFields/Select';
import Input         from 'components/FormFields/Input';

import axios         from 'axios';
import anime         from 'animejs';
import _debounce     from 'lodash/debounce';

import googleTracker from 'tracker';

export default {
  name: 'RegistrationForm',
  components: {Checkbox, TextArea, Select, Input, StyledButton},

  data(){
    return{
      modalOpen: false,
      buttonMsg: 'SUBMIT',
      formFields: {
        salutation: '',
        name: '',
        email: '',
        age: '',
        number: '',
        interestedProperties: '',
        tac: ''
      }
    };
  },

  methods: {
    validateFields: _debounce(function(){
      this.$validator.validateAll().then(allPassed => {
        if(allPassed){
          this.buttonMsg = 'SUBMITTING';

          const formData = new FormData();
          for(const field in this.formFields) formData.append(`ffs${field}`, this.formFields[field]);

          axios.post(window.location.href, formData)
          .then(() => {
            googleTracker.sendEvent('Registration Form', 'Form : Submit');
            fbq('track', 'CompleteRegistration');

            // toggle modal opening
            this.modalOpen = true;

            // change button text
            this.buttonMsg = 'SUBMIT SUCCESS';
          })
          .catch(error => {
            alert(error);
            console.log(error);
          });
        }
      });
    }, 5000, {
      leading: true,
      trailing: false
    }),

    modalClose(){
      this.modalOpen = false;

      // change button text
      this.buttonMsg = 'SUBMIT';

      // reset fields and errors
      for(let field in this.formFields) this.formFields[field] = '';
      this.$validator.reset();
    },

    enter(el, done){
      anime({
        targets: el,
        opacity: [0, 1],
        duration: 450,
        easing: 'easeOutQuart'
      });

      anime({
        targets: el.querySelector('.modal--inner'),
        translateY: ['100%', 0],
        duration: 450,
        easing: 'easeOutQuart',

        complete: done
      });
    },

    leave(el, done){
      anime({
        targets: el,
        opacity: [1, 0],
        duration: 450,
        easing: 'easeOutQuart'
      });

      anime({
        targets: el.querySelector('.modal--inner'),
        translateY: ['0', '100%'],
        duration: 450,
        easing: 'easeOutQuart',

        complete: done
      });
    }
  }
};
</script>

<style lang="css">
</style>
