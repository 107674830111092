var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "section" },
    [
      _vm._t("outer:before"),
      _vm._v(" "),
      _c("div", { staticClass: "section--inner" }, [_vm._t("default")], 2),
      _vm._v(" "),
      _vm._t("outer:after"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }