/**
 * show modal
 * @param {boolean} autoShow `true` to auto show
 * @returns 
 */
export default function initModal(autoShow = false, delay = 1000) {
    const MODAL_DELAY = delay
    const homeModal = document.querySelector('#home-modal')

    if (homeModal !== null || homeModal !== undefined) {
        if (!autoShow) return

        const closeButton = homeModal.querySelector('.button-cross')
        if (!closeButton) {
            throw 'close button not existed, please definded element with .button-cross'
        }

        const backdrop = document.createElement('div')
        backdrop.classList.add('modal-backdrop', 'fade', 'show')
        
        // show modal after delay
        setTimeout(() => {
            document.body.append(backdrop)
            document.body.classList.add('modal-open')
            homeModal.classList.add('d-block')
            
            setTimeout(() => {
                homeModal.classList.add('show')
            }, 100);
        }, MODAL_DELAY);

        // close modal if click outside of current modal
        homeModal.addEventListener('click', function(e) {
            if (e.target.classList.contains('modal')) {
                removeModal()
            }
        })

        // close modal with button
        closeButton.addEventListener('click', () => {
            removeModal()
        })

        function removeModal() {
            document.body.classList.remove('modal-open')
            homeModal.classList.remove('show')

            setTimeout(() => {
                homeModal.classList.remove('d-block')
                document.body.removeChild(backdrop)
            }, 300);
        }
    }
}