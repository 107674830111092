<template lang="html">
  <form v-on:submit.prevent="validateFields" method="post">
    <div class="row">
      <div class="col-sm-6">
        <Select
          label="Salutation"
          v-model="formFields.salutation"
          v-validate="'required'"
          :error="errors.first('Salutation')"
        >
          <option value=""></option>
          <option value="Tan Sri">Tan Sri</option>
          <option value="Puan Sri">Puan Sri</option>
          <option value="Dato Seri">Dato Seri</option>
          <option value="Datin Seri">Datin Seri</option>
          <option value="Dato">Dato</option>
          <option value="Datin">Datin</option>
          <option value="Mr">Mr</option>
          <option value="Mrs">Mrs</option>
          <option value="Ms">Ms</option>
          <option value="Dr">Dr</option>
          <option value="Prof">Prof</option>
        </Select>
      </div>
      <div class="col-sm-6">
        <Input
          label="Name"
          type="text"
          v-model="formFields.name"
          v-validate="'required'"
          :error="errors.first('Name')"
        />
      </div>
    </div>
    <div class="row">
      <div class="col-sm-6">
        <Input
        label="Email"
        type="email"
        v-model="formFields.email"
        v-validate="'required|email'"
        :error="errors.first('Email')"
        />
      </div>
      <div class="col-sm-6">
        <Input
          label="Phone"
          type="text"
          v-model="formFields.phone"
          v-validate="'required|numeric'"
          :error="errors.first('Phone')"
        />
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <TextArea
        label="Message"
        type="textarea"
        v-model="formFields.message"
        v-validate="'required'"
        :error="errors.first('Message')"
        />
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <styled-button v-bind:text="buttonMsg"></styled-button>
      </div>
    </div>

    <transition
      v-bind:css="false"
      v-on:enter="enter"
      v-on:leave="leave"
    >
      <div class="modal--form" v-if="modalOpen">
        <div class="modal--inner">
          <h1>THANK YOU FOR CONTACTING US.</h1>
          <p>We will get back to you soon.</p>
          <StyledButton v-on:button-click="modalClose">CLOSE</StyledButton>
        </div>
      </div>
    </transition>
  </form>
</template>

<script>
import StyledButton  from 'components/StyledButton';

import Checkbox      from 'components/FormFields/Checkbox';
import TextArea      from 'components/FormFields/TextArea';
import Select        from 'components/FormFields/Select';
import Input         from 'components/FormFields/Input';

import axios         from 'axios';
import anime         from 'animejs';
import _debounce     from 'lodash/debounce';

import googleTracker from 'tracker';

export default {
  name: 'ContactForm',
  components: {Checkbox, TextArea, Select, Input, StyledButton},

  data(){
    return{
      modalOpen: false,
      buttonMsg: 'SUBMIT',
      formFields: {
        salutation: '',
        name: '',
        email: '',
        phone: '',
        message: ''
      }
    };
  },

  methods: {
    validateFields: _debounce(function(){
      this.$validator.validateAll().then(allPassed => {
        if(allPassed){
          this.buttonMsg = 'SUBMITTING';

          const formData = new FormData();
          for(const field in this.formFields) formData.append(`ffs${field}`, this.formFields[field]);

          axios.post(window.location.href, formData)
          .then(() => {
            googleTracker.sendEvent('Contact Us Form', 'Form : Submit');
            fbq('track', 'CompleteRegistration', {content_name: 'Contact Form'});

            // toggle modal opening
            this.modalOpen = true;

            // change button text
            this.buttonMsg = 'SUBMIT SUCCESS';
          })
          .catch(error => {
            alert(error);
            console.log(error);
          });
        }
      });
    }, 1000, {
      leading: true,
      trailing: false
    }),

    modalClose(){
      this.modalOpen = false;

      // change button text
      this.buttonMsg = 'SUBMIT';

      // reset fields and errors
      for(let field in this.formFields) this.formFields[field] = '';
      this.$validator.reset();
    },

    enter(el, done){
      anime({
        targets: el,
        opacity: [0, 1],
        duration: 450,
        easing: 'easeOutQuart'
      });

      anime({
        targets: el.querySelector('.modal--inner'),
        translateY: ['100%', 0],
        duration: 450,
        easing: 'easeOutQuart',

        complete: done
      });
    },

    leave(el, done){
      anime({
        targets: el,
        opacity: [1, 0],
        duration: 450,
        easing: 'easeOutQuart'
      });

      anime({
        targets: el.querySelector('.modal--inner'),
        translateY: ['0', '100%'],
        duration: 450,
        easing: 'easeOutQuart',

        complete: done
      });
    }
  }
};
</script>

<style lang="css">
</style>
