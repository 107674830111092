var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "transition",
    {
      attrs: { css: false },
      on: {
        "before-leave": _vm.beforeLeave,
        enter: _vm.enter,
        leave: _vm.leave,
        "after-leave": _vm.afterLeave,
      },
    },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.isVisible,
              expression: "isVisible",
            },
          ],
          staticClass: "tab",
          class: { "is-visible": _vm.isVisible },
        },
        [_vm._t("default")],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }