import _throttle from 'lodash/throttle';

export default function logoFade(){
  const logo = document.querySelector('.header--logo');

  window.addEventListener('scroll', _throttle(() => {
    const scrollPos = window.scrollY || window.pageYOffset;

    if(scrollPos > 0) logo.classList.add('is-hidden');
    else logo.classList.remove('is-hidden');
  }, 200));
}
