<template lang="html">
  <div class="collection--carousel">
    <div class="carousel--header">
      <h1>Collection</h1>
      <SubNavigationList
      class="carousel--filter"
      v-bind:items="statuses"
      v-on:change="filterSlides($event)"
      />
    </div>

    <Carousel
      v-bind:slides="slides"
      v-on:open-modal="toggleModal($event)"
    />

    <transition
    v-bind:css="false"
    v-on:before-enter="beforeEnter"
    v-on:enter="toggleAnimation"
    v-on:leave="toggleAnimation"
    v-on:after-leave="afterLeave"
    >
      <div class="carousel--modal" v-if='currentSlide !== null'>
        <SlideContent
          v-bind:content="currentSlide"
          v-on:close-modal="toggleModal"
        />
      </div>
    </transition>
  </div>
</template>

<script>
import SubNavigationList from 'components/SubNavigationList';

import Carousel          from 'components/CollectionCarousel/Carousel';
import SlideContent      from 'components/CollectionCarousel/SlideContent';

import anime             from 'animejs';
import _throttle         from 'lodash/throttle';

export default {
  name: 'CollectionCarousel',
  components: { SubNavigationList, Carousel, SlideContent },

  data(){
    return {
      slides: [],
      statuses:  [],
      currentStatusFilter: 'all',

      currentSlide: null,
      preempted: false
    };
  },

  created(){
    // get collections data
    const collectionData = JSON.parse(document.getElementById('collectionData').innerHTML);

    // this.slides = collectionData.slides;
    collectionData.slides.forEach(slide => this.slides.push({content: slide, show: true}));
    this.statuses  = collectionData.statuses;

    // create fake data
    // for(let i = 0; i < 3; i++){
    //   collectionData.slides.forEach(slide => this.slides.push({content: slide, show: true}));
    // }

    this.preemptContent();
  },

  mounted(){
    this.carouselComponents = document.querySelectorAll('.carousel--track, .carousel--header');

    document.addEventListener('DOMContentLoaded', () => this.setTopOffset());

    window.addEventListener('resize', _throttle(() => this.setTopOffset, 200));

    if(this.preempted){
      anime({
        targets: '.carousel--track, .carousel--header',
        opacity: 0,

        duration: 0,
        easing: 'easeInOutQuart',

        complete: this.setAbsolutePosition
      });

      this.filterSlides(this.currentStatusFilter);
    }
  },

  methods: {
    preemptContent(){
      // get url
      const currentUrl = window.location.href;
      // get query
      const _itemQuery = /collections\/([^/]*)\/?/.exec(currentUrl);

      // if doesn't exist return
      if(_itemQuery[1].length <= 0) return;

      // get correct lookup group
      const itemQuery = _itemQuery[1];

      // get appropriate content
      this.currentSlide = this.slides.find(slide => slide.content.slug == itemQuery).content;
      // signal content has pre-emptively been set
      this.preempted = true;
    },

    filterSlides(value){
      if(value == 'all') this.slides.forEach(slide => slide.show = true);
      else this.slides.forEach(slide => {
        if(slide.content.status == value) slide.show = true;
        else slide.show = false;
      });

      this.currentStatusFilter = value;
    },

    setTopOffset(){
      // explicitly set top offset for when animating
      document.querySelectorAll('.carousel--track, .carousel--header').forEach(el => {
        el.style.top = `${el.offsetTop}px`;
      });
    },

    setAbsolutePosition(){
      // set position absolute to allow overlapping
      this.carouselComponents.forEach(el => el.style.position = 'absolute');
    },

    resetPosition(){
      // set position absolute to allow overlapping
      this.carouselComponents.forEach(el => el.style.position = '');
    },

    toggleModal(index){
      if(this.currentSlide == null){
        this.currentSlide = this.slides[index].content;

        history.pushState(null, null, `/collection/${this.currentSlide.slug}`);
      } else{
        this.currentSlide = null;
        this.$el.removeAttribute('style');

        history.pushState(null, null, '/collection', '');
      }
    },

    // ===============================
    // Animations
    // ===============================
    beforeEnter(modal){
      modal.style.opacity = 0;
    },

    afterLeave(){
      this.resetPosition();
    },

    toggleAnimation(modal, done){
      const flip = !this.currentSlide ? true : false;

      this.setAbsolutePosition();

      anime.timeline()
      // thumbs
      .add({
        targets: '.slide--thumb',
        translateY: () => returnVal([0, 40]),
        opacity: () => returnVal([1, 0]),

        easing: 'easeOutQuart',
        duration: 200,

        delay: (el, i) => i*20
      })
      // modal
      .add({
        targets: modal,

        translateY: () => returnVal([15, 0]),
        opacity: () => returnVal([0, 1]),
        duration: 400,
        easing: 'easeOutQuart',
        offset: flip ? 100 : 600,

        begin: () => modal.style.pointerEvents = 'none',
        complete: () => modal.removeAttribute('style')
      })
      // header
      .add({
        targets: '.carousel--header, .carousel--track',
        opacity: () => returnVal([1, 0]),

        easing: 'easeOutQuart',
        duration: 400,
        offset: flip ? 100 : 600,
        complete: done
      });

      function returnVal(value){
        if(flip) return value.reverse();
        else return value;
      }
    }
  }
};
</script>
