<template lang="html">
  <div class="slide--thumb">
    <div class="slide--image" v-bind:class="{ 'is-logo': !content.image && content.logo}">
      <img v-bind:src="content.image ? content.image.url : content.logo ? content.logo.url : `${window._templateDir}/img/projects/trion.jpg`" alt="" />
    </div>

    <div class="slide--title">
      <h1>{{ content.title }}</h1>
      <p>@ {{ content.location }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SlideThumb',
  props: ['content'],

  data(){
    return {
      window: window
    };
  }
};
</script>

<style lang="css">
</style>
