<template>
  <div class="carousel--wrapper">
    <div class="carousel">
      <!-- <Slide ref="slide1">
        
      </Slide> -->
      <Slide ref="slide1">
        <template slot="content">
          <div></div>
        </template>
      </Slide>
      <Slide ref="slide2" v-bind:button="[
        {
          text: 'BOOK NOW',
          href: '//all.accor.com/a545',
          target: '_blank',
          tracker: 'Home Carousel | Slide : Mercure Hotels Kuala Lumpur Trion | Button : Book Now'
        }
      ]">
        <template slot="content">
          <div> <img style="max-width: 400px;height: auto;" v-bind:src="`${window._templateDir}/img/binastra-hotel-text.png`" alt=""> </div>
        </template>
      </Slide>
      <Slide ref="slide3">
        <template slot="content">
          <div> <img style="max-width: 400px;height: auto;" v-bind:src="`${window._templateDir}/img/trion-text-2.png`" alt=""> </div>
        </template>
      </Slide>
      <!-- <Slide ref="slide2">
        <template slot="content">
          <div> <img style="max-width: 350px;" v-bind:src="`${window._templateDir}/img/mercure-text.png`" alt=""> </div>
        </template>
      </Slide> -->
      <!-- <Slide ref="slide3">
        <template slot="content">
          <div> <img style="max-width: 350px;" v-bind:src="`${window._templateDir}/img/trion-text.png`" alt=""> </div>
        </template>
      </Slide> -->

      <!-- Mercure -->
      <!-- <Slide ref="slide3">
        <template slot="header">
          <div class="for-desktop">
            <div class="image"> <img v-bind:src="`${window._templateDir}/img/mercure-logo.svg`" alt="Mercure"> </div>
          </div>
          <div class="for-mobile">
            // <div class="image"> <img v-bind:src="`${window._templateDir}/img/mercure-logo-black.svg`" alt="Mercure"> </div>
            <div class="image"> <img v-bind:src="`${window._templateDir}/img/mercure-logo.svg`" alt="Mercure"> </div>
          </div>
        </template>
        <template slot="content">
          <p>Mercure TRION @ Kuala Lumpur</p>
          <p>Tallest Hotel Building in South East Asia</p>
          <p>Opening Soon in December 2023</p>
        </template>
      </Slide> -->

      <!-- Trion Lease -->

      <!-- <Slide 
        ref="slide2"
      >
        <template slot="header">
          <div class="for-desktop">
            <div class="image"> <img v-bind:src="`${window._templateDir}/img/logo-trion-neon.png`" alt=""> </div>
          </div>
          <div class="for-mobile"> -->
      <!-- <div class="image"> <img v-bind:src="`${window._templateDir}/img/logo-trion.png`" alt=""> </div> -->
      <!-- <div class="image"> <img v-bind:src="`${window._templateDir}/img/logo-trion-neon.png`" alt=""> </div>
          </div>
        </template>
        <template slot="content">
          <p>Retail Space For Leasing.</p>
          <p>For more information: <a href="tel:+60138998999">6013 - 899 8999</a></p>
        </template>
      </Slide> -->

      <!-- <Slide ref="slide4" v-bind:button="[
        {
          text: 'REGISTER NOW',
          href: '//suriagarden.com.my/',
          target: '_blank',
          tracker: 'Home Carousel | Slide : Suria Garden | Button : Register Now'
        }
      ]"> -->
      

      <!-- <Slide ref="slide5" v-bind:button="[
        {
          text: 'REGISTER NOW',
          href: '//trion2.com.my',
          target: '_blank',
          tracker: 'Home Carousel | Slide : Trion2 | Button : Open For sale'
        },
        {
          text: 'VIEW 3D UNITS',
          href: 'https://trion2.com.my/#3d-show-units',
          target: '_blank',
          tracker: 'Home Carousel | Slide : Trion2 | Button : View 3D Show Units',
        }
      ]">
        <template slot="header">
          <div class="for-desktop">
            <div class="image"> <img v-bind:src="`${window._templateDir}/img/logo-trion2-white.png`" alt="Trion2">
            </div>
          </div>
          <div class="for-mobile">
            <div class="image"> <img v-bind:src="`${window._templateDir}/img/logo-trion2-white.png`" alt="Trion2">
            </div>
          </div>
        </template>
        <template slot="content">
          <p>Trion2@KL is an exciting freehold development set within a premier address. Take your lifestyle to the next
            level with over 30 lifestyle facilities centred around personal reflection and exciting self-discovery –
            it's time to live better.</p>
        </template>
      </Slide> -->

      <!-- <Slide ref="slide5" v-bind:button="[
        {
          text: 'REGISTER NOW',
          href: '//trion.com.my',
          target: '_blank',
          tracker: 'Home Carousel | Slide : Trion | Button : Open For sale'
        },
        {
          text: 'VIEW 3D UNITS',
          href: 'http://trion.com.my/#view-3d',
          target: '_blank',
          tracker: 'Home Carousel | Slide : Trion | Button : View 3D Show Units',
        }
      ]">
        <template slot="header">
          <div class="for-desktop">
            <div class="image"> <img v-bind:src="`${window._templateDir}/img/logo-trion-neon.png`" alt=""> </div>
          </div>
          <div class="for-mobile">
            <div class="image"> <img v-bind:src="`${window._templateDir}/img/logo-trion-neon.png`" alt=""> </div>
          </div>
        </template>
        <template slot="content">
          <p>Trion@KL is where an iconic residence, retail options, and a world-class hotel come together to create the
            ultimate urban living experience. Come to the Trion@KL sales gallery and experience urbanite living!</p>
          <p>For more information: <a href="tel:60138998999">6013 - 899 8999</a></p>
        </template>
      </Slide> -->

      <Slide ref="slide4">
        <template slot="content">
          <div> <img style="max-width: 400px;height: auto;" v-bind:src="`${window._templateDir}/img/binastra-retails-text.png`" alt=""> </div>
        </template>
        <!-- <template slot="header">
          <div class="for-desktop">
            <div class="image"> <img v-bind:src="`${window._templateDir}/img/binastra-SuriaGarden-text.png`"
                alt="Suria Garden"> </div>
          </http://localhost:3000/wp-content/themes/binastraland/img/binastra-web-text.pngdiv>
          <div class="for-mobile">
            <div class="image"> <img v-bind:src="`${window._templateDir}/img/binastra-SuriaGarden-text.png`"
                alt="Suria Garden"> </div>
          </div>
        </template>
        <template slot="content">
          <p>This is the essence of verdant family living in a nature-inspired home,
            all within the well-connected locale of Puchong. At Suria Garden, the young have
            the opportunity to experience the wonders of nature while families savour
            precious moments spent together in an environment built to nurture.</p>
        </template> -->
      </Slide>
      
      <Slide ref="slide5">
        <template slot="content">
          <div> <img style="max-width: 400px;height: auto;" v-bind:src="`${window._templateDir}/img/binastra-BBP2-text.png`" alt=""> </div>
        </template>
        <!-- <template slot="header">
          <div class="for-desktop">
            <div class="image"> <img v-bind:src="`${window._templateDir}/img/binastra-SuriaGarden-text.png`"
                alt="Suria Garden"> </div>
          </http://localhost:3000/wp-content/themes/binastraland/img/binastra-web-text.pngdiv>
          <div class="for-mobile">
            <div class="image"> <img v-bind:src="`${window._templateDir}/img/binastra-SuriaGarden-text.png`"
                alt="Suria Garden"> </div>
          </div>
        </template>
        <template slot="content">
          <p>This is the essence of verdant family living in a nature-inspired home,
            all within the well-connected locale of Puchong. At Suria Garden, the young have
            the opportunity to experience the wonders of nature while families savour
            precious moments spent together in an environment built to nurture.</p>
        </template> -->
      </Slide>
      
      <Slide ref="slide6">
        <template slot="content">
          <div> <img style="max-width: 400px;height: auto;" v-bind:src="`${window._templateDir}/img/binastra-BBP1-text.png`" alt=""> </div>
        </template>
        <!-- <template slot="header">
          <div class="for-desktop">
            <div class="image"> <img v-bind:src="`${window._templateDir}/img/binastra-SuriaGarden-text.png`"
                alt="Suria Garden"> </div>
          </http://localhost:3000/wp-content/themes/binastraland/img/binastra-web-text.pngdiv>
          <div class="for-mobile">
            <div class="image"> <img v-bind:src="`${window._templateDir}/img/binastra-SuriaGarden-text.png`"
                alt="Suria Garden"> </div>
          </div>
        </template>
        <template slot="content">
          <p>This is the essence of verdant family living in a nature-inspired home,
            all within the well-connected locale of Puchong. At Suria Garden, the young have
            the opportunity to experience the wonders of nature while families savour
            precious moments spent together in an environment built to nurture.</p>
        </template> -->
      </Slide>

      <Slide ref="slide7" v-bind:button="[
        {
          text: 'OPEN FOR SALES',
          href: '//suriagarden.com.my/',
          target: '_blank',
          tracker: 'Home Carousel | Slide : Suria Garden | Button : Register Now'
        }
      ]">
        <template slot="content">
          <div> <img style="max-width: 400px;height: auto;" v-bind:src="`${window._templateDir}/img/binastra-SuriaGarden-text.png`" alt=""> </div>
        </template>
        <!-- <template slot="header">
          <div class="for-desktop">
            <div class="image"> <img v-bind:src="`${window._templateDir}/img/binastra-SuriaGarden-text.png`"
                alt="Suria Garden"> </div>
          </http://localhost:3000/wp-content/themes/binastraland/img/binastra-web-text.pngdiv>
          <div class="for-mobile">
            <div class="image"> <img v-bind:src="`${window._templateDir}/img/binastra-SuriaGarden-text.png`"
                alt="Suria Garden"> </div>
          </div>
        </template>
        <template slot="content">
          <p>This is the essence of verdant family living in a nature-inspired home,
            all within the well-connected locale of Puchong. At Suria Garden, the young have
            the opportunity to experience the wonders of nature while families savour
            precious moments spent together in an environment built to nurture.</p>
        </template> -->
      </Slide>

      <Slide ref="slide8" v-bind:button="[
        {
          text: 'ABOUT US',
          href: '/about-us',
          tracker: 'Home Carousel | Slide : Assurance in Workmanship | Button : About Us'
        }
      ]">
        <template slot="header">
          <h1>ASSURANCE</h1>
          <h1>IN WORKMANSHIP</h1>
        </template>
        <template slot="content">
          <p>We take pride in not just making you a house, but a better home to begin life at. From the finest details
            to the bigger picture, we craft only the best.</p>
        </template>
      </Slide>

      <Slide ref="slide9" v-bind:button="[
        {
          text: 'ABOUT US',
          href: '/about-us',
          tracker: 'Home Carousel | Slide : Assurance in Quality | Button : About Us'
        }
      ]">
        <template slot="header">
          <h1>ASSURANCE</h1>
          <h1>IN QUALITY</h1>
        </template>
        <template slot="content">
          <p>All deliverance from the ground up is taken with the highest quality so that each project resonates its own
            character, along with a touch of class and a dash of virtue.</p>
        </template>
      </Slide>

      <Slide ref="slide10" v-bind:button="[
        {
          text: 'ABOUT US',
          href: '/about-us',
          tracker: 'Home Carousel | Slide : Timely Delivery | Button : About Us'
        }
      ]">
        <template slot="header">
          <h1>ACHIEVING</h1>
          <h1>GREATER EXCELLENCE</h1>
        </template>
        <template slot="content">
          <p>More than just a multi-award-winning developer, we’re constantly improving based on our many decades of
            experience and ever-expanding list of projects, to achieve new heights.</p>
        </template>
      </Slide>
    </div>

    <div class="carousel--pagination">
      <div v-for='(slide, index) in totalSlides' v-bind:key='slide.id' v-bind:class='{ "is-current": index == 0 }'
        v-bind:data-section-index='index' v-on:click='changeIndex(index)'
        v-bind:gtag-click="`Home Carousel | Pagination | Slide : ${index}`">
        <div></div>
      </div>
    </div>
  </div>
</template>

<script>
import Siema from 'siema';
import anime from 'animejs';

import Slide from 'components/HomeCarousel/Slide';
import Utils from 'utils';
import initModal from '../js/components/modal'

export default {
  name: 'HomeCarousel',
  components: { Slide },

  data() {
    return {
      prevIndex: 0,
      currentIndex: 0,
      nextIndex: 1,

      startPagination: false,
      totalSlides: 9,

      window: window,

      hideBoxes: true
    };
  },

  watch: {
    startPagination: function (value) {
      if (value == true) {
        this.initPagination();

        const autoshow = true
        initModal(autoshow)

        // animate first slide
        if (document.readyState != 'complete') {
          window.addEventListener('DOMContentLoaded', () => {
            this.getSlide(this.currentIndex).animate();
          });
        } else {
          this.getSlide(this.currentIndex).animate();
        }
      }
    },

    currentIndex: function (value) {
      const box = document.querySelector('.floating--boxes');

      if (value === 0) this.hideBoxes = true;
      else this.hideBoxes = false;
    },

    hideBoxes: function (hidden) {
      if (Utils.checkMobile()) return;

      if (hidden) {
        animateOut();
      } else {
        animateIn();
      }

      function animateIn() {
        anime.remove('.floating--boxes');
        anime.remove('.floating--content .content--wrapper');

        anime({
          targets: '.floating--boxes',
          width: [0, '16vw'],
          opacity: [0, 0.4],
          duration: 700,
          easing: 'easeOutQuart',

          delay: (t, i) => i * 2 * 200 + 800
        });

        anime({
          targets: '.floating--content .content--wrapper',
          translateX: ['50%', 0],
          opacity: [0, 1],
          duration: 700,
          easing: 'easeOutQuart',

          delay: 1000
        });
      }

      function animateOut() {
        anime.remove('.floating--boxes');
        anime.remove('.floating--content .content--wrapper');

        anime({
          targets: '.floating--boxes',
          width: ['16vw', 0],
          opacity: [0.4, 0],
          duration: 700,
          easing: 'easeOutQuart',

          delay: (t, i) => i * 2 * 200 + 800
        });

        anime({
          targets: '.floating--content .content--wrapper',
          translateX: [0, '50%'],
          opacity: [1, 0],
          duration: 700,
          easing: 'easeOutQuart',

          delay: 1000
        });
      }
    }
  },

  mounted() {
    this.options = {
      animDur: 1100,
      holdDur: 10000,
      easing: 'easeInOutCubic'
    };

    // initialize siema carousel
    this.carousel = new Siema({
      selector: '.carousel',
      loop: 'true',
      draggable: false,
      easing: 'cubic-bezier(0.645, 0.045, 0.355, 1.000)',
      duration: this.options.animDur
    });

    // set attribute
    this.carousel.innerElements.forEach((el, index) => el.setAttribute('data-section-index', index));

    // get pagination stuff
    this.pagination = document.querySelector('.carousel--pagination');
    this.paginationDots = this.pagination.querySelectorAll('[data-section-index]');
    this.paginationDotsInner = this.pagination.querySelectorAll('[data-section-index] > div');
  },

  methods: {
    getSlide(index) {
      return this.$refs[Object.keys(this.$refs)[index]];
    },

    changeIndex(index) {
      // prevent click when its same as prev or when animating
      if (this.currentIndex == index || this.animating) return;

      // reset pagination states
      this.resetPagination(index);
      this.toNext(index);
    },

    toNext(index) {
      // store previous index
      this.prevIndex = this.carousel.currentSlide;

      // determine direction by comparing previous index and destination index
      this.direction = Math.sign(this.prevIndex - index) == -1 ? 'forwards' : 'back';

      // move carousel
      this.carousel.goTo(index);

      // signal child to get ready for animation
      this.getSlide(index).setStartState();

      // animate previous
      if (this.direction == 'forwards') this.animatePrev(this.prevIndex, this.direction);
      else this.animatePrev(index, this.direction);

      // store current index
      this.currentIndex = this.carousel.currentSlide;

      // get next index
      this.nextIndex = this.currentIndex < this.carousel.innerElements.length - 1 ? this.currentIndex + 1 : 0;

      // restart animation
      anime({ duration: this.options.animDur, complete: () => this.initPagination() });
    },

    // Animate Previous Slide
    animatePrev(index, direction) {
      anime.remove(this.carousel.innerElements);

      // get targets
      const targets = this.carousel.innerElements[index].querySelector('.slide--background');
      const value = ['0', '60%'];
      if (direction == 'back') value.reverse();

      // animate
      anime({
        targets: targets,
        translateX: value,

        duration: this.options.animDur,
        easing: this.options.easing,
        begin: () => this.animating = true,
        complete: () => {
          document.querySelectorAll('.slide--background').forEach(el => el.removeAttribute('style'));
          this.animating = false;

          // animate
          this.getSlide(this.currentIndex).animate();
        }
      });
    },

    // Controls pagination progress and handles switching
    initPagination() {
      const $this = this;
      const target = this.paginationDots[this.nextIndex].childNodes[0];

      anime({
        targets: target,
        width: '100%',

        duration: this.options.holdDur,
        easing: 'linear',

        complete: () => {
          target.parentNode.classList.add('is-current');
          target.removeAttribute('style');

          // navigate to next slide
          this.toNext(this.nextIndex);

          // restart progress bar
          retractPrev();
        }
      });

      function retractPrev() {
        const _target = $this.paginationDots[$this.prevIndex].childNodes[0];

        anime({
          targets: _target,
          scaleX: 0,
          translateY: ['-50%', '-50%'],

          duration: $this.options.animDur,
          easing: 'easeOutQuart',

          complete: () => {
            _target.removeAttribute('style');
            _target.parentNode.classList.remove('is-current');
          }
        });
      }
    },

    resetPagination(index) {
      const target = this.paginationDots[index].childNodes[0];
      const others = Array.from(this.paginationDotsInner)
        .filter(el => el.parentNode.getAttribute('data-section-index') != index);

      anime.remove(this.paginationDotsInner);

      // animate clicked index
      anime({
        targets: target,
        width: '100%',

        easing: 'easeOutQuart',
        duration: this.options.animDur,

        complete: () => {
          target.parentNode.classList.add('is-current');
          target.removeAttribute('style');
        }
      });

      // animate out others
      anime({
        targets: others,
        width: 0,

        easing: 'easeOutQuart',
        duration: this.options.animDur,

        complete: () => {
          others.forEach(el => {
            el.parentNode.classList.remove('is-current');
            el.removeAttribute('style');
          });
        }
      });
    }
  }
};
</script>
