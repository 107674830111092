var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "header",
    [
      _c("div", { staticClass: "header--logo" }, [
        _c(
          "a",
          { attrs: { href: "/?skip", "gtag-click": "Navigation Menu | Logo" } },
          [
            _c("img", {
              attrs: {
                src: `${_vm.window._templateDir}/img/logo-binastraland-new.svg`,
                alt: "",
              },
            }),
          ]
        ),
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "hamburger",
          class: { "is-active": _vm.navActive },
          attrs: { "gtag-click": "Navigation Menu | Hamburger" },
          on: { click: _vm.toggleNavigation },
        },
        [_c("span"), _vm._v(" "), _c("span"), _vm._v(" "), _c("span")]
      ),
      _vm._v(" "),
      _c("HeaderNavigation", {
        class: { "is-active": _vm.navActive },
        attrs: { navActive: _vm.navActive },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }