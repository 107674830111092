var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "slide--thumb" }, [
    _c("div", { staticClass: "slide--title" }, [
      _c("h2", { domProps: { innerHTML: _vm._s(_vm.content.title) } }),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "slide--date" }, [
      _c("h1", [_vm._v(_vm._s(_vm.content.date[1]))]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }