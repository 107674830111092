var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "footer",
    [
      _c("p", [
        _vm._v(
          "© " +
            _vm._s(_vm.currentYear) +
            " Binastra Land. All Rights Reserved."
        ),
      ]),
      _vm._v(" "),
      _c("ul", [
        _c("li", { on: { click: _vm.openDisclaimer } }, [_vm._v("Disclaimer")]),
        _vm._v("\n    /\n    "),
        _c("li", { on: { click: _vm.openPPolicy } }, [
          _vm._v("Privacy Policy"),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "transition",
        { attrs: { css: false }, on: { enter: _vm.enter, leave: _vm.leave } },
        [
          _vm.modalContent != ""
            ? _c("div", { staticClass: "modal--legal" }, [
                _c("div", { staticClass: "modal--inner" }, [
                  _c("div", {
                    staticClass: "modal--close",
                    on: { click: _vm.closeModal },
                  }),
                  _vm._v(" "),
                  _c("div", {
                    staticClass: "modal--content",
                    domProps: { innerHTML: _vm._s(_vm.modalContent) },
                  }),
                ]),
              ])
            : _vm._e(),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }