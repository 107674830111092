<template>
  <div class="button">
    <a
      v-if="href"
      v-bind:href="href"
      v-bind:target="target"
      v-bind:rel="rel"
      v-on:click="$emit('button-click')"
    ></a>
    <button
      v-else
      v-bind:type="type"
      v-on:click="$emit('button-click')"
    ></button>
    <span v-if="text" v-html="text"></span>
    <span v-else ><slot></slot></span>
  </div>
</template>

<script>
import SVG from 'svg.js';
import anime from 'animejs';

export default {
  name: 'customButton',
  props: {
    'href': {type: String},
    'target': {type: String},
    'rel': {type: String},
    'type': {type: String},
    'text': {type: String},
    'auto': {type: Boolean, default: true}
  },

  watch: {
    text: function(){
      this.$nextTick(()=> this.resize());
    }
  },

  mounted(){
    if(document.readyState != 'complete') document.addEventListener('DOMContentLoaded', () => this.init());
    else this.init();
  },

  methods: {
    init(){
      this.buildBackground();
      this.resetAnimation();

      if(this.auto) this.animate();
    },

    buildBackground(){
      this.button = this.$el;

      const bounds = this.button.getBoundingClientRect();
      const w      = bounds.width;
      const h      = bounds.height;

      this.draw = SVG(this.button).size(w, h).viewbox(`0 0 ${w} ${h}`);
      this.polyStroke = this.draw.polygon(` ${w-15}, 0 0, 0 0, ${h} ${w} ${h}`);
      this.polyStroke.stroke({width: 1, color: '#000'}).fill('none');

      this.polyFill = this.draw.polygon(` ${w-15}, 0 0, 0 0, ${h} ${w} ${h}`);
      this.polyFill.fill('#000');
    },

    resize(){
      this.button = this.$el;

      const bounds = this.button.getBoundingClientRect();
      const w      = bounds.width;
      const h      = bounds.height;

      // resize bg
      this.draw.size(w, h).viewbox(`0 0 ${w} ${h}`);

      anime({
        targets: [this.polyStroke.node, this.polyFill.node],
        points: [`${w-15}, 0 0, 0 0, ${h} ${w} ${h}`],
        duration: 400,
        easing: 'easeOutQuart'
      });
    },

    resetAnimation(){
      this.polyStroke.style('stroke-dashoffset', anime.setDashoffset(this.polyStroke.node));
      this.polyFill.style('transform', 'translateX(-200px)');

      this.button.querySelector('span').style.opacity = 0;
    },

    animate(){
      const anim = anime.timeline({
        easing: 'easeInOutQuart',
        duration: 1500,
      });

      anim
      .add({
        targets: this.$el,
        opacity: [0, 1],
        duration: 400,
        offset: 0
      })
      .add({
        targets: this.polyStroke.node,
        strokeDashoffset: [anime.setDashoffset, 0],
        offset: 0
      })
      .add({
        targets: this.polyFill.node,
        translateX: ['-200px', '0'],
        offset: 0
      })
      .add({
        targets: this.button.querySelector('span'),
        opacity: [0, 1],
        offset: 0
      });
    }
  }
};
</script>
